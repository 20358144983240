.validator-wrapper {
  width: 100%;
  text-align: left;
  
  span[class^='DialogWrapper-VDS'] {
    border: 0.0625rem solid rgb(111, 113, 113) !important;
  }
}

.validator-wrapper div[role='alert'] div p {
  display: none;
}

.error {
  font-weight: 700;
  color: #c44904;
}

.success {
  font-weight: 700;
  color: #008330;
}
.medium
{
  font-weight: 700;
  color: #FCD667;
}

.validation-points{
  display: flex;
    align-items: center;
}