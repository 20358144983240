.calendar-card {
  width: 100%;
  box-sizing: border-box;
}

.event-item {
  display: flex;
  width: 100%;
  height: auto;
  min-height: 112px;
  border-radius: 0px 8px 8px 8px;
  overflow: hidden;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.02);

  .event-profile {
    width: 88px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;

    .event-badge {
      display: flex;
      justify-content: flex-end;
    }
  }

  .event-info {
    width: calc(100% - 88px);
    // min-height: 136px;
    // min-height: 144px;
    height: auto;
    position: relative;
    display: flex;
    flex-flow: column;

    .event-top {
      display: flex;
      justify-content: space-between;

      .event-left {
        display: inline-flex;
        overflow: hidden;
        white-space: nowrap;

        .additional-tag {
          display: inline-flex;
          flex-wrap: nowrap;
          align-items: center;

          .tag-icon {
            display: inline-flex;
          }
        }

        .event-time {
          display: inline-flex;
          flex-wrap: nowrap;
          align-items: center;
          white-space: nowrap;

          .calendar-icon {
            display: inline-flex;
          }
        }
      }

      .event-right {
        display: inline-flex;

        .event-tag {
          background-color: #F6F6F6;
          border-radius: 8px;
          display: inline-flex;
          align-items: center;
        }
      }
    }

    .event-content {
      display: flex;
      width: 100%;
      height: 100%;
      gap: 8px;

      .event-text {
        width: 100%;

        >* {
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
      }

      .right-arrow {
        display: inline-flex;
        align-items: flex-end;
        margin-top: auto;

        &:focus-visible {
          outline: 1px dashed #000;
          outline-offset: 2px;
        }

        >* {
          cursor: pointer;
        }
      }
    }


    @media (max-width: 767px) {
      .right-arrow {
        right: 12px;
        bottom: 12px;
      }
    }
  }
}