.multi-grants-component {
    margin: 0px auto;
    max-width: 1020px;
    @media screen and (max-width: 767px) {
      margin-left: 16px;
    }

    .card-body-auto{
      @media screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }
}