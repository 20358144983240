/** @format */

.course-details-body {
  padding: 48px 0px 64px 0px;
  @media screen and (max-width: 767px) {
    padding: 48px 0px 64px 0px;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 48px 0 64px 0;
  }
}

.intrested-in-carousel-container {
  padding: 48px 0px 64px 84px;
  background: var(--vds-background-secondary-light, #f6f6f6);

  @media screen and (max-width: 767px) {
    padding: 48px 0px 64px 16px;
  }

  .intrested-in-title {
    color: var(--vds-elements-text-icons-borders-primary-onlight, #000);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    padding-bottom: 32px;
  }

  .intrested-in-carousel {
    .course-carousel {
      margin-bottom: 0;

      div[peek='standard'] {
        padding-left: 0px;
      }
    }
  }
}

.ico-mobile {
  .n-textCenter {
    > div {
      margin: 0 auto;
    }
  }
  div.share-content {
    cursor: pointer;
    width: 100%;
  }
}

.share-content-copy-message {
  font-size: 12px;
  position: absolute;
  background: #fff;
  padding: 8px;
  margin-left: -60px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0px 1px 2px 2px rgb(246, 246, 246);
  &.hidden {
    display: none;
  }
}
// .image-bg {
//   background: linear-gradient(0deg, rgba(246, 246, 246, 0.50) 0%, rgba(246, 246, 246, 0.50) 100%), linear-gradient(90deg, #FFF 24.72%, rgba(255, 255, 255, 0.00) 97.08%),    
//     lightgray 273.519px -239.75px / 114.2% 228.281% no-repeat;
//   background-blend-mode: screen, normal, normal;
// }

.bottom-widget{
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e5e5e5;
  background-color: #f6f6f6;
  z-index: 9999;
  @media screen and (max-width: 767px) {
    width: auto;
  }

  .carousel-container-fix{
    .text-content{
      min-width: 500px;
      @media screen and (max-width: 767px) {
        min-width: 100%;
      }
    }
    .content{
      display: flex;
      // justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}

.sticky-bottom-widget{
  position: sticky;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e5e5e5;
  background-color: #f6f6f6;
  z-index: 9999;
  @media screen and (max-width: 767px) {
    width: auto;
  }
  .carousel-container-fix{
    .text-content{
      min-width: 500px;
      @media screen and (max-width: 767px) {
        min-width: 100%;
      }
    }
    .content{
      display: flex;
      // justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}