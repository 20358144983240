.grants{
    .grants-hero{
      text-align: center;
      img{
        width: 135px;
        height: 102px;
      }
    }
   .grant-status{
    text-align: center;
    background-color: #FF8027;
    .button{
      display: flex;
      justify-content: center;
    }
   }
   .instructions{
    display: flex;
    gap: 40px;
    text-align: left;
    div{
      flex: 1;
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }
   }
   .faqs{
    text-align: center;
   }
  
   .progress{
    display: flex;
    gap: 36px;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    .progress-tile{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid #000000;
      width: 334px;
      height: 160px;
      border-radius: 8px;
    }
   }
  
   .bb-1x{
    border-bottom: 1px solid black;
   }
  
   .unlocked{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .ico-container{
      background-color: #FF8027;
      height: 104px;
      width: 104px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
   }
  
   .resources {
    &.bg-gray {
      background-color: #F6F6F6;
    }
  
    .text-area {
      display: flex;
      justify-content: space-between;
      gap: 40px;
  
      .top-action-links {
        display: inline-flex;
        align-items: center;
        gap: 32px;
  
        .action-link {
          cursor: pointer;
          display: inline-flex;
          align-items: center;
  
          &:focus-visible {
            outline: 1px dashed #000;
            outline-offset: 2px;
          }
        }
      }
    }
  
    @media (max-width: 767px) {
      .text-area {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }
  
  }
  }

  .grant-apply-title {
    width: 50%;
    margin: 0 25%;
  
    @media (max-width:767px) {
      width: 100%;
      margin: 0;
    }
  }