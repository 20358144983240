.secondaryCourceCard {
  width: 100%;

  .badge {
    background-color: #0077b4;
    color: white;
    transform: translate(24px, 54px);
    display: inline-block;
    border-radius: 8px;
    position: absolute;
    margin-top: -30px;
  }

  .description {
    width: 100%;
    bottom: 12px;
    box-sizing: border-box;
    .info {
      p {
        display: inline-block;
        text-transform: capitalize;
      }
    }

    .label {
      overflow: hidden;
      h5 {
        padding-top: 0;
      }
    }

    .duration > svg {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .bg-image {
    // width: 107%;
    // margin-left: -12px;
    height: 70%;
    background-position: center;
    background-size: cover;
    border-radius: 10px 10px 0 0;
  }
}
