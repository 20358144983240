.app-autocomplete {
  width: 100%;
  position: relative;
}

.autocomplete-input {
  border: 0.0625rem solid rgb(111, 113, 113);
  padding: 8px;
  font-family: Verizon-NHG-eDS;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.03125rem;
  margin: 0px;
  padding: 10px 35px 11px 11px;
  width: -webkit-fill-available;
  width: -moz-available;
  border-radius: 4px;
}

.autocomplete-input:focus {
  outline: none;
  border-color: #000;
}

.autocomplete-input.input-focus {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.autocomplete-wrapper {
  position: relative;
  width: 100%;
}

.autocomplete-wrapper .close-icon {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.no-autocomplete {
  color: #6f7171;
  padding: 8px;
  text-align: left;
}

.autocomplete {
  border: 0.0625rem solid rgb(111, 113, 113);
  border-top-width: 0;
  list-style: none;
  margin: 0;
  max-height: 176px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.autocomplete::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.autocomplete::-webkit-scrollbar-track {
  background: #d8dada;
}

.autocomplete::-webkit-scrollbar-thumb {
  background: black;
}

.autocomplete li {
  padding: 11px;
  text-align: left;
}

.autocomplete > .active,
.autocomplete li:hover {
  cursor: pointer;
  font-weight: 700;
}

.autocomplete li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}
