.onbording-container {
  text-align: center;
  width: 100%;
}

.onbording-container .tab-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 384px;
}

.onbording-container .tab-container > div {
  width: 100%;
}

@media (max-width: 767px) {
  .onbording-container {
    text-align: left;
  }
  .onbording-container .tab-container {
    width: 100%;
  }
}
