.videos {
  width: 100%;
  height: unset;
}

.video-wrapper {
  // padding: 20px;
}

.title {
  // font-weight: 300;
  // font-size: 64px;
  // margin-bottom: 10px
}

.main {
  background-color: black;
  color: white;
}

.header {
  width: 66%;
  margin: auto;
  margin-bottom: 10px;
}

.cards {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 65px 20px 80px 20px;
  .card {
    // min-height: 200px;
    height: unset;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white;
    color: black;
    // height: 100%;
    min-width: 215px;
    padding: 20px;
    border-radius: 8px;
    flex: 1;
  }
  .person-details {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .person-name {
    font-size: 16px;
    font-weight: 700;
  }

  .person-location {
    font-size: 16px;
    font-weight: 400;
  }

  .person-description {
    font-size: 24px;
    font-weight: 400;
    text-align: left;
  }

  .person-subtitle {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
  }

  .all-details {
    flex-direction: column;
    display: flex;
    gap: 5px;
    align-items: flex-start;
    text-align: left;
  }
}

.carouselContainer {
  padding-left: 0 !important;
  margin-left: 170px !important;
  transform: unset;
}
.mobileCards {
  display: block;
  padding-bottom: 20px;
  .slotContainer {
    @media screen and (min-width: 480px) {
      flex: 0 0 408px !important;
    }
  }
  .slotContainer:first-child {
    padding-left: 0px !important;
  }
  .slot {
    height: 100% !important;
  };
}
@media (max-width: 991px) {
  
  .cards {
    display: none;
  }
  .main {
    padding-left: 15px;
    padding-right: 15px;
  }
}
