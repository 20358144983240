/** @format */

.course-content-header {
  background-color: #000000;
  color: #f6f6f6;
  padding: 0 0px 48px 0px;
  @media screen and (max-width: 767px) {
    padding: 0 0px 48px 0px;
  }
}

.course-format p {
  display: inline-block;
  margin-right: 4px;
}
.course-format {
  display: flex;
  align-items: center
}