/** @format */

.expert-image-container img {
  border-radius: 8px;
  width: 100%;
  height: auto;
}

@media screen and (max-width: 767px) {
  .expertsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline !important;
  }
  .expertsContainer .image-section {
    width: 50%;
  }
}

.ico-mobile {
  display: flex;
  flex-wrap: wrap;
}

.ico-mobile div {
  width: 25%;
}

.expertsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.expertsContainer .image-section {
  flex: 1;
}

.expertsContainer .image-section img {
  aspect-ratio: 1/1;
  width: 100%;
  border-radius: 50%;
}

.expertsContainer .description {
  flex: 4;
}

.course-modules {
  .gwclrH {
    font-size: 14px;
    max-height: 48px;
    overflow-y: hidden;
  }
  .faVOdg {
    font-size: 10px;
  }
  .hiwuIB {
    padding: 16px;
  }
}
.publishedOnDate {
  color: #6F7171;
  margin-top: 24px;
  font-weight: 700;
}
.line {
  width: 80px;
  margin-top: 24px;
}

.vertical-stepper {
  .step {
    // border-left: 1px solid black;
    position: relative;

    .recording {

      padding: 16px 24px 16px 24px;
      gap: 12px;
      border-radius: 0px 0px 8px 8px;
      opacity: 0px;
      background: var(--background-secondary, rgba(246, 246, 246, 1));
      display: flex;
      justify-content: space-between;

      a {
        font-size: 12px;
        word-break: normal;
        width: 93.5px;
      }

    }

    .progress-icon {
      background-color: white;
      border: 1px solid #a7a7a7;
      border-radius: 100%;
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 5;

    }

    .step-line {
      top: 0px;
      left: 14px;
      position: relative;
      border-left: 1px solid #a7a7a7;
      z-index: 4;

      .box-shadow{
        box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.03);
        transition: 1s;
        :hover{
          box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.06);
        }
      }

      .format-name{
        border-radius: 4px 4px 0px 0px;
        padding: 6px 12px 6px 12px;
        box-shadow: 2px 12px 12px 0px #00000033;
        width: fit-content;
      }

      .calendar-card{
        .event-item{
          border-radius: 0px 8px 8px 8px;
          :hover{
            box-shadow:none !important;
  
          }
          
          .event-profile{
            height: 100%;
            aspect-ratio: 1/1;
            width: 136px;
          }
        }
      }

      .mw200 {
        max-width: 300px;
      }

      .content {
        // padding-top: 30px;
        margin-top: -28px;

      }

      .end {}
    }

    .green-border {
      border-color: #008331 !important;
    }

    .no-border {
      border: none;
      background-color: white;
      box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.03);

    }


  }
}

@media screen and (max-width: 767px) {
  .vertical-stepper {
    .step {
      .recording {

        display: block;

        a {
          font-size: 12px;
          word-break: normal;
          width: 100%;
          margin-top: 8px;
          // margin-left: 20px;
        }

      }

      .progress-icon {
        width: 24px !important;
        height: 24px !important;
      }

      .step-line {
        left: 12px;

        .content {
          margin-top: -23px;
        }
      }


    }

  }
}

.grey-widget {
  background-color: #F6F6F6;
  border-radius: 8px;

  h4,
  h5,
  h6,
  h3,
  h2 {
    padding-top: 0px;
  }
}



.bg-green-26{
  background-color: #008331 !important;
}

