/**
 * Copyright Beingana Jim Junior, 2021 and all the contributors. License Cn be found in the LICENCE file
 *
 * @format
 */

$theme: var(--vds-elements-text-icons-borders-brandhighlight, #e00);
$back: #00000075;

.one {
  width: 100%;
  color: $theme;
  background: black;
  position: relative;

  &:hover {
    .video-react-lower-bar {
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 767px) {
  .one {
    height: 212px;
  }
}

.progress-video-react {
  display: flex;
  align-items: center;
  height: 2px;
  width: 100%;
  margin-bottom: 5px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.664);
  transition: all 0.4s;

  .finnished {
    background-color: $theme;
    height: 100%;
    align-items: center;
    position: relative;
    display: flex;
  }

  .point {
    width: 0px;
    height: 0px;
    margin-left: auto;
    border-radius: 50%;
    position: absolute;
    right: -3px;
    pointer-events: none;
    background-color: $theme;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.5);
    }
  }

  &:focus {
    background-color: yellow;
  }

  &:hover {
    height: 5px;

    .point {
      width: 10px;
      height: 10px;
    }
  }
}

.video-react-loading {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.089);
  border: white 2px solid;
  border-top: blue 2px solid;
  top: 33.33%;
  left: 43.33%;
  animation: loading 1s infinite;
  position: absolute;
  /* margin: auto;
	margin-top: auto;
	margin-bottom: auto; */
}

@keyframes loading {
  100% {
    transform: rotate(360deg);
  }
}

.video-react-lower-bar {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0rem 0.3rem;
  background: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.404),
    rgba(0, 0, 0, 0.589),
    rgba(0, 0, 0, 0.863)
  );
  transition: all 0.5s ease-out;
  opacity: 0;
}

.video-react-lower-bar.stick-control-bar {
  opacity: 1;
}

.one > video {
  width: 100%;
  height: 100%;
  z-index: -1;
}

.video-react-controls > div {
  display: flex;
  height: fit-content;
  text-align: center;
  padding-top: 5px;

  svg {
    font-size: 1.5rem !important;
    margin-top: auto !important;
    width: 100% !important;
    margin-bottom: auto !important;
  }
}

.video-react-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.time-stamps {
  width: 100%;
  display: flex;
  font-size: 0.7rem;
  color: white;
  text-shadow: black 1px 1px 1px;

  .fullstime {
    margin-left: auto;
    padding-right: 5px;
  }

  .current {
    padding-left: 5px;
  }
}

.video-react-more {
  position: relative;
}

.video-react-play,
.video-react-pause,
.video-react-volume,
.video-react-fullscreen,
.video-react-rewind,
.video-react-forward,
.video-react-more {
  border: rgba(209, 208, 208, 0) 1px solid;
  transition: all 0.3s;
  cursor: pointer;
  color: white;

  &:hover {
    background-color: rgba(128, 128, 128, 0.089);
    color: $theme;
  }
}

.video-react-pause:hover,
.video-react-play:hover,
.video-react-volume:hover,
.video-react-rewind:hover,
.video-react-more:hover,
.video-react-fullscreen:hover,
.video-react-forward:hover {
  color: $theme;
}
.finnished {
  background-color: $theme !important;
}
.point {
  background-color: $theme !important;
}

span.icon {
  padding: 5px;
  font-size: 1rem;
}

.video-react-menu {
  background-color: rgba(7, 7, 7, 0.671);
  width: 150px;
  height: fit-content;
  bottom: 101%;
  padding: 0.3rem 5px;
  font-size: 0.8rem;
  transform: scale(0);
  transition: all 0.4s ease-in-out;
  opacity: 0;
  border-radius: 5px;
  right: 0px;
  position: absolute;
  color: #b4b3b3;
  z-index: 3;
  box-shadow:
    #00000052 1px 1px 15px,
    #00000052 -1px -1px 15px;
}

.list- {
  display: flex;
  align-items: center;
  color: #b4b3b3;
  text-decoration: none;

  .text {
    margin-left: 10px;
  }

  &:hover {
    background: rgba(128, 128, 128, 0.486);
    color: white;
  }

  span.icon > svg {
    color: $theme;
    font-size: 1.5rem !important;
    width: 20px !important;
  }
}

.list-1 {
  display: flex;
  align-items: center;

  span {
    flex: 1;
  }

  .icon {
    color: $theme;
    border-radius: 5px;
    transition: all 0.2s ease-out;

    &:hover {
      background-color: gray;
      color: white;
    }
  }
}

.video-react-volume {
  position: relative;
  display: flex;
  align-items: center;

  .finnished {
    background-color: $theme;
    height: 100%;
  }

  .volume-add {
    opacity: 0;
    display: flex;
    align-items: center;
    width: fit-content;
    height: fit-content;
    color: white;
    transition: all 0.5s;
  }

  .volume-div {
    width: 0px;
    height: 3px;
    margin: auto;
    background: white;
    transition: all 0.5s;
    border-radius: 5px;
  }

  &:hover {
    .volume-add {
      opacity: 1;
    }

    .volume-div {
      width: 80px;
    }
  }
}

.video-react-error {
  background-color: red;
  z-index: 10;
  border-radius: 5px;
  position: absolute;
  width: fit-content;
  top: 10px;
  opacity: 0;
  margin: auto;
  font-size: 0.9rem;
  color: white;
  display: flex;
  align-items: center;
  box-shadow: black 1px 1px 25px;
  left: 10px;
  transition: all 0.5s ease-in-out;

  span {
    margin: 5px;
    text-align: center;
    vertical-align: middle;
  }

  .cancel {
    padding: 0.3rem;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.37);
    }
  }

  svg {
    font-size: 1.5rem;
  }

  padding: 0.1rem;
}

.menu-c {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.menu-contxt {
  position: relative;
  margin: 0px;
}

.video-rect-context {
  background-color: rgba(0, 0, 0, 0.829);
  width: fit-content;
  position: absolute;
  height: fit-content;
  color: white;
  border-radius: 3px;
  box-shadow:
    #000000b4 1px 1px 15px,
    #000000b4 -1px -1px 15px;
  padding: 0.5rem;

  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;

    li {
      font-size: 0.9rem;
      display: flex;
      align-items: center;
      padding: 0.3rem;
      cursor: pointer;
      border-radius: 3px;
      transition: all 0.3s ease-out;

      &:hover {
        background-color: rgba(255, 255, 255, 0.199);
      }

      svg {
        font-size: 1.5rem;
        padding-right: 0.2rem;
        color: rgb(230, 228, 228);
      }
    }
  }
}

.poster {
  position: absolute;
  z-index: 15;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & > div {
    position: relative;
    background: black;
    height: 100%;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      margin: 0px;
    }

    div {
      width: fit-content;
      height: fit-content;
      // padding: 1rem 2rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: white;
      // background: $theme;
      border-radius: 25%;

      svg {
        font-size: 12rem;
        height: 96px;
        width: 96px;
      }

      cursor: pointer;

      &:hover {
        // background: #2985fd;
      }

      margin: auto;
      z-index: 3;
    }
  }
}

@media only screen and (max-width: 767px) {
  .poster {
    & > div {
      div {
        padding: 0px;
      }
    }
  }
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: fit-content;
  font-size: 0.5rem;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  bottom: 230%;
  opacity: 0;
  transition: opacity 1s;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
