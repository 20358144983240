.secondaryCourceCard {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column;

  .path-des {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    // position: relative;
    // margin-bottom: -30px;
    z-index: 100;
    margin-left: -24px;
    border-radius: 0px 0px 8px 8px;
  }

  .image-icons {
    position: absolute;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .button-icon {
      width: 32px;
      height: 32px;
      border-radius: 100px;
      background-color: #fff;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  .image img {
    width: 100%;
    aspect-ratio: 312/198;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
  }

  .description {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;

    .durations {
      position: absolute;
      bottom: 0px;
      width: 100%;

      .textarea {
        justify-content: space-between;
        align-items: center;
        width: 90%;
        display: flex;

      }

      .duration-text {
        height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        align-items: center;

        // display: flex;
        // align-items: center;
        a {
          border-bottom: 0;

          span.ml-2X {
            text-decoration: underline;
          }
        }
      }

      a {
        font-size: 11px;
      }
    }

    .label {
      width: 100%;
      display: block;
      overflow: hidden;
    text-overflow: ellipsis;
    // white-space: nowrap;

      .course-label {
        display: flex;
        align-items: center;
      }

      .dot-wrap {
        display: inline-flex;
        width: 2px;
        height: 2px;
        background-color: #000;
        border-radius: 100px;
      }
    }

    .title-label {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}