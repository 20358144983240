.testimoniesCard{
  background-color: white;
  border-radius: 8px;
  width: 100%;
  height: 78%;
  .description{
    text-align: left;
  }
}

.testimoniesCard .heading-Container{
  display: flex;
  align-items: center;
  
}

.testimoniesCard .heading-Container img {
  height: 104px;
  width: 104px;
  border-radius: 50%;
}

.testimoniesCard .heading-Container .labels {
  text-align: left;
}

@media screen and (max-width:767px) {
  .testimoniesCard .heading-Container img {
      height: 64px;
      width: 64px;
      border-radius: 50%;
  }
}







.card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  color: black;
  height: 100%;
  width: 100%;
  // max-width: 300px;
  padding: 24px 24px 0px 24px;
  border-radius: 8px;
}

.person-details-mobile {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  text-align: left;
  img {
    width: 100px;
    height: 100px;
  }
}

.person-name {
  font-size: 16px;
  font-weight: 700;
}

.person-location {
  font-size: 16px;
  font-weight: 400;
}

.person-description-mobile {
  font-size: 20px;
  font-weight: 400;
  text-align: left;
}

.person-subtitle {
  font-size: 16px;
  font-weight: 400;
}

.all-details {
  flex-direction: column;
  display: flex;
  gap: 5px;
  align-items: flex-start;
}
