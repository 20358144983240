.learningPathCard {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: row;

  .ideas {
    border-radius: 8px;
    display: flex;
    align-items: center;
    width: fit-content;
  }

  .image-icons {
    position: absolute;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .button-icon {
      width: 32px;
      height: 32px;
      border-radius: 100px;
      background-color: #fff;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  .image {
    height: 100%;
    aspect-ratio: 1/1;
  }

  .image img {
    // width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: 8px;
    object-fit: cover;
  }

  .description {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;

    .durations {
      display: flex;
      position: absolute;
      width: 73%;
      bottom: 0px;
      justify-content: space-between;
      align-items: center;

      .duration-text {
        height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        // display: flex;
        // align-items: center;
        a {
          border-bottom: 0;

          span.ml-2X {
            text-decoration: underline;
          }
        }
      }

      a {
        font-size: 11px;
      }
    }

    .label {
      width: 100%;
      display: block;

      .course-label {
        display: flex;
        align-items: center;
      }

      .dot-wrap {
        display: inline-flex;
        width: 2px;
        height: 2px;
        background-color: #000;
        border-radius: 100px;
      }
    }

    .title-label {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
      // overflow: hidden;
    }
  }
}

.n-flex-learning.mb-12X{
  margin-bottom: 48px!important;
}

.learning-topics{
  .chip-container {
    justify-content: flex-start;
  }
}

.learning-path-carousel{
  div[class^=OuterTileContainer-VDS]{
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.03) !important;
    :hover{
      // box-shadow: 0 16px 24px rgba(0, 0, 0, 0.6) !important;
    }
  }
}

.n-flex-learning {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .filter {}

  .options {}

  .spanish-toggle {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.spanish-toggle {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .n-flex-learning {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .filter {
      // border-bottom: 1px solid #dadada;
      // padding-bottom: 16px;
      // margin-bottom: 16px;
      width: 100%;
    }

    .options {
      width: 100%;
      border-top: 1px solid #dadada;
      padding-top: 16px;
      margin-top: 16px;
    }

    .spanish-toggle {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }
}