.chips-container {
  max-width: 600px;
}

.widthfix {
  max-width: 387px;
}

.stepper-container {
  max-width: 387px;
  width: 100%;
}

.title-container {
  max-width: 387px;
  width: 100%;
}

.input-container {
  width: 100%;
  max-width: 387px;
}

.notification-container {
  width: 100%;
  max-width: 387px;
  text-align: left;
}

.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.autocomplete-container {
  width: 100%;
}

.terms-bottom-text {
  font-size: 12px;
  line-height: 16px;
  font-family: Verizon-NHG-eTX;
}

.n-bold {
  font-weight: bold;
}

@media (max-width: 767px) {
  .terms-bottom-text br {
    display: none;
  }
  .register-container {
    align-items: flex-start!important;
  }
}

.loader {
  width: 100px;
  height: 100px;
  border: 3px solid #d8dada;
  border-bottom-color: #ff0000;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-height-fix {
  height: 100px;
  display: block;
}

.u-textLeft {
  text-align: left;
}
.u-textCenter {
  text-align: center;
}
.captcha-container {
  margin-top: 24px;
  margin-bottom: 6px;
}
.g-recaptcha {
  transform: scale(1.28);
  transform-origin: 0 7px;
}

@media (max-width: 412px) {
  .g-recaptcha {
    transform: scale(0.87);
    transform-origin: 0 7px;
  }
}

.bbzEkr {
  outline: 1px solid black;
}

span[class^='InputContainer-VDS'] {
  border: 0.0625rem solid rgb(111, 113, 113) !important;
}
