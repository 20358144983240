
.n-widthFull {
    width: 100%
}

.n-maxWidthFull {
    max-width: 100%;
}

.n-widthAuto {
    width: auto
}

.n-displayBlock {
    display: block
}

.n-displayInlineBlock {
    display: inline-block
}

.n-displayNone {
    display: none
}

.n-displayFlex {
    display: flex;
}

.n-justifyContentCenter {
    justify-content: center;
}

.n-alignItemsCenter {
    align-items: center;
}

.n-flexWrap {
    flex-wrap: wrap;
}

.n-flexNoWrap {
    flex-wrap: nowrap;
}

.n-flexDirectionRow {
    flex-direction: row;
}

.n-flexDirectionCol {
    flex-direction: column;
}

.n-flexRowReverse {
    flex-direction: row-reverse;
}

.n-flexColReverse {
    flex-direction: column-reverse;
}

.n-textLeft {
    text-align: left;
}

.n-textRight {
    text-align: right;
}

.n-textCenter {
    text-align: center;
}

.ma-0X {
    margin: 0px
}

.ml-0X {
    margin-left: 0px
}

.mr-0X {
    margin-right: 0px
}

.mt-0X {
    margin-top: 0px
}

.mb-0X {
    margin-bottom: 0px
}

.mh-0X {
    margin-left: 0px;
    margin-right: 0px
}

.mv-0X {
    margin-top: 0px;
    margin-bottom: 0px
}

.ma-auto {
    margin: auto
}

.mt-auto {
    margin-top: auto
}

.mb-auto {
    margin-bottom: auto
}

.ml-auto {
    margin-left: auto
}

.mr-auto {
    margin-right: auto
}

.mh-auto {
    margin-left: auto;
    margin-right: auto
}

.mv-auto {
    margin-top: auto;
    margin-bottom: auto
}

.mh-0-auto {
    margin: 0px auto
}

.mv-0-auto {
    margin: auto 0px
}

.pa-0X {
    padding: 0px
}

.pl-0X {
    padding-left: 0px
}

.pr-0X {
    padding-right: 0px
}

.pt-0X {
    padding-top: 0px
}

.pb-0X {
    padding-bottom: 0px
}

.ph-0X {
    padding-left: 0px;
    padding-right: 0px
}

.pv-0X {
    padding-top: 0px;
    padding-bottom: 0px
}

.ma-1X {
    margin: var(--space1X)
}

.mt-1X {
    margin-top: var(--space1X)
}

.mb-1X {
    margin-bottom: var(--space1X)
}

.ml-1X {
    margin-left: var(--space1X)
}

.mr-1X {
    margin-right: var(--space1X)
}

.mv-1X {
    margin-top: var(--space1X);
    margin-bottom: var(--space1X)
}

.mh-1X {
    margin-left: var(--space1X);
    margin-right: var(--space1X)
}

.ma-2X {
    margin: var(--space2X)
}

.mt-2X {
    margin-top: var(--space2X)
}

.mb-2X {
    margin-bottom: var(--space2X)
}

.ml-2X {
    margin-left: var(--space2X)
}

.mr-2X {
    margin-right: var(--space2X)
}

.mv-2X {
    margin-top: var(--space2X);
    margin-bottom: var(--space2X)
}

.mh-2X {
    margin-left: var(--space2X);
    margin-right: var(--space2X)
}

.ma-3X {
    margin: var(--space3X)
}

.mt-3X {
    margin-top: var(--space3X)
}

.mb-3X {
    margin-bottom: var(--space3X)
}

.ml-3X {
    margin-left: var(--space3X)
}

.mr-3X {
    margin-right: var(--space3X)
}

.mv-3X {
    margin-top: var(--space3X);
    margin-bottom: var(--space3X)
}

.mh-3X {
    margin-left: var(--space3X);
    margin-right: var(--space3X)
}

.ma-4X {
    margin: var(--space4X)
}

.mt-4X {
    margin-top: var(--space4X)
}

.mb-4X {
    margin-bottom: var(--space4X)
}

.ml-4X {
    margin-left: var(--space4X)
}

.mr-4X {
    margin-right: var(--space4X)
}

.mv-4X {
    margin-top: var(--space4X);
    margin-bottom: var(--space4X)
}

.mh-4X {
    margin-left: var(--space4X);
    margin-right: var(--space4X)
}

.ma-5X {
    margin: var(--space5X)
}

.mt-5X {
    margin-top: var(--space5X)
}

.mb-5X {
    margin-bottom: var(--space5X)
}

.ml-5X {
    margin-left: var(--space5X)
}

.mr-5X {
    margin-right: var(--space5X)
}

.mv-5X {
    margin-top: var(--space5X);
    margin-bottom: var(--space5X)
}

.mh-5X {
    margin-left: var(--space5X);
    margin-right: var(--space5X)
}

.ma-6X {
    margin: var(--space6X)
}

.mt-6X {
    margin-top: var(--space6X)
}

.mb-6X {
    margin-bottom: var(--space6X)
}

.ml-6X {
    margin-left: var(--space6X)
}

.mr-6X {
    margin-right: var(--space6X)
}

.mv-6X {
    margin-top: var(--space6X);
    margin-bottom: var(--space6X)
}

.mh-6X {
    margin-left: var(--space6X);
    margin-right: var(--space6X)
}


.ma-8X {
    margin: var(--space8X)
}

.mt-8X {
    margin-top: var(--space8X)
}

.mb-8X {
    margin-bottom: var(--space8X)
}

.ml-8X {
    margin-left: var(--space8X)
}

.mr-8X {
    margin-right: var(--space8X)
}

.mv-8X {
    margin-top: var(--space8X);
    margin-bottom: var(--space8X)
}

.mh-8X {
    margin-left: var(--space8X);
    margin-right: var(--space8X)
}

.ma-12X {
    margin: var(--space12X)
}

.mt-12X {
    margin-top: var(--space12X)
}

.mb-12X {
    margin-bottom: var(--space12X)
}

.ml-12X {
    margin-left: var(--space12X)
}

.mr-12X {
    margin-right: var(--space12X)
}

.mv-12X {
    margin-top: var(--space12X);
    margin-bottom: var(--space12X)
}

.mh-12X {
    margin-left: var(--space12X);
    margin-right: var(--space12X)
}

.ma-16X {
    margin: var(--space16X)
}

.mt-16X {
    margin-top: var(--space16X)
}

.mb-16X {
    margin-bottom: var(--space16X)
}

.ml-16X {
    margin-left: var(--space16X)
}

.mr-16X {
    margin-right: var(--space16X)
}

.mv-16X {
    margin-top: var(--space16X);
    margin-bottom: var(--space16X)
}

.mh-16X {
    margin-left: var(--space16X);
    margin-right: var(--space16X)
}

.ma-24X {
    margin: var(--space24X)
}

.mt-24X {
    margin-top: var(--space24X)
}

.mb-24X {
    margin-bottom: var(--space24X)
}

.ml-24X {
    margin-left: var(--space24X)
}

.mr-24X {
    margin-right: var(--space24X)
}

.mv-24X {
    margin-top: var(--space24X);
    margin-bottom: var(--space24X)
}

.mh-24X {
    margin-left: var(--space24X);
    margin-right: var(--space24X)
}

.ma-32X {
    margin: var(--space32X)
}

.mt-32X {
    margin-top: var(--space32X)
}

.mb-32X {
    margin-bottom: var(--space32X)
}

.ml-32X {
    margin-left: var(--space32X)
}

.mr-32X {
    margin-right: var(--space32X)
}

.mv-32X {
    margin-top: var(--space32X);
    margin-bottom: var(--space32X)
}

.mh-32X {
    margin-left: var(--space32X);
    margin-right: var(--space32X)
}

.pa-1X {
    padding: var(--space1X)
}

.pt-1X {
    padding-top: var(--space1X)
}

.pb-1X {
    padding-bottom: var(--space1X)
}

.pl-1X {
    padding-left: var(--space1X)
}

.pr-1X {
    padding-right: var(--space1X)
}

.pv-1X {
    padding-top: var(--space1X);
    padding-bottom: var(--space1X)
}

.ph-1X {
    padding-left: var(--space1X);
    padding-right: var(--space1X)
}

.pa-2X {
    padding: var(--space2X)
}

.pt-2X {
    padding-top: var(--space2X)
}

.pb-2X {
    padding-bottom: var(--space2X)
}

.pl-2X {
    padding-left: var(--space2X)
}

.pr-2X {
    padding-right: var(--space2X)
}

.pv-2X {
    padding-top: var(--space2X);
    padding-bottom: var(--space2X)
}

.ph-2X {
    padding-left: var(--space2X);
    padding-right: var(--space2X)
}

.pa-3X {
    padding: var(--space3X)
}

.pt-3X {
    padding-top: var(--space3X)
}

.pb-3X {
    padding-bottom: var(--space3X)
}

.pl-3X {
    padding-left: var(--space3X)
}

.pr-3X {
    padding-right: var(--space3X)
}

.pv-3X {
    padding-top: var(--space3X);
    padding-bottom: var(--space3X)
}

.ph-3X {
    padding-left: var(--space3X);
    padding-right: var(--space3X)
}

.pa-4X {
    padding: var(--space4X)
}

.pt-4X {
    padding-top: var(--space4X)
}

.pb-4X {
    padding-bottom: var(--space4X)
}

.pl-4X {
    padding-left: var(--space4X)
}

.pr-4X {
    padding-right: var(--space4X)
}

.pv-4X {
    padding-top: var(--space4X);
    padding-bottom: var(--space4X)
}

.ph-4X {
    padding-left: var(--space4X);
    padding-right: var(--space4X)
}

.pa-5X {
    padding: var(--space5X)
}

.pt-5X {
    padding-top: var(--space5X)
}

.pb-5X {
    padding-bottom: var(--space5X)
}

.pl-5X {
    padding-left: var(--space5X)
}

.pr-5X {
    padding-right: var(--space5X)
}

.pv-5X {
    padding-top: var(--space5X);
    padding-bottom: var(--space5X)
}

.ph-5X {
    padding-left: var(--space5X);
    padding-right: var(--space5X)
}

.pa-6X {
    padding: var(--space6X)
}

.pt-6X {
    padding-top: var(--space6X)
}

.pb-6X {
    padding-bottom: var(--space6X)
}

.pl-6X {
    padding-left: var(--space6X)
}

.pr-6X {
    padding-right: var(--space6X)
}

.pv-6X {
    padding-top: var(--space6X);
    padding-bottom: var(--space6X)
}

.ph-6X {
    padding-left: var(--space6X);
    padding-right: var(--space6X)
}

.pa-8X {
    padding: var(--space8X)
}

.pt-8X {
    padding-top: var(--space8X)
}

.pb-8X {
    padding-bottom: var(--space8X)
}

.pl-8X {
    padding-left: var(--space8X)
}

.pr-8X {
    padding-right: var(--space8X)
}

.pv-8X {
    padding-top: var(--space8X);
    padding-bottom: var(--space8X)
}

.ph-8X {
    padding-left: var(--space8X);
    padding-right: var(--space8X)
}

.pa-12X {
    padding: var(--space12X)
}

.pt-12X {
    padding-top: var(--space12X)
}

.pb-12X {
    padding-bottom: var(--space12X)
}

.pl-12X {
    padding-left: var(--space12X)
}

.pr-12X {
    padding-right: var(--space12X)
}

.pv-12X {
    padding-top: var(--space12X);
    padding-bottom: var(--space12X)
}

.ph-12X {
    padding-left: var(--space12X);
    padding-right: var(--space12X)
}

.pa-16X {
    padding: var(--space16X)
}

.pt-16X {
    padding-top: var(--space16X)
}

.pb-16X {
    padding-bottom: var(--space16X)
}

.pl-16X {
    padding-left: var(--space16X)
}

.pr-16X {
    padding-right: var(--space16X)
}

.pv-16X {
    padding-top: var(--space16X);
    padding-bottom: var(--space16X)
}

.ph-16X {
    padding-left: var(--space16X);
    padding-right: var(--space16X)
}

.pa-24X {
    padding: var(--space24X)
}

.pt-24X {
    padding-top: var(--space24X)
}

.pb-24X {
    padding-bottom: var(--space24X)
}

.pl-24X {
    padding-left: var(--space24X)
}

.pr-24X {
    padding-right: var(--space24X)
}

.pv-24X {
    padding-top: var(--space24X);
    padding-bottom: var(--space24X)
}

.ph-24X {
    padding-left: var(--space24X);
    padding-right: var(--space24X)
}

.pa-32X {
    padding: var(--space32X)
}

.pt-32X {
    padding-top: var(--space32X)
}

.pb-32X {
    padding-bottom: var(--space32X)
}

.pl-32X {
    padding-left: var(--space32X)
}

.pr-32X {
    padding-right: var(--space32X)
}

.pv-32X {
    padding-top: var(--space32X);
    padding-bottom: var(--space32X)
}

.ph-32X {
    padding-left: var(--space32X);
    padding-right: var(--space32X)
}

.lh-1X {
    line-height: var(--space1X)
}

.lh-2X {
    line-height: var(--space2X)
}

.lh-3X {
    line-height: var(--space3X)
}

.lh-4X {
    line-height: var(--space4X)
}

.lh-5X {
    line-height: var(--space5X)
}

.lh-6X {
    line-height: var(--space6X)
}

.lh-8X {
    line-height: var(--space8X)
}

.lh-12X {
    line-height: var(--space12X)
}

.lh-16X {
    line-height: var(--space16X)
}

.lh-24X {
    line-height: var(--space24X)
}

.lh-32X {
    line-height: var(--space32X)
}

.fs-1X {
    font-size: var(--space1X)
}

.fs-2X {
    font-size: var(--space2X)
}

.fs-3X {
    font-size: var(--space3X)
}

.fs-4X {
    font-size: var(--space4X)
}

.fs-5X {
    font-size: var(--space5X)
}

.fs-6X {
    font-size: var(--space6X)
}

.fs-8X {
    font-size: var(--space8X)
}

.fs-12X {
    font-size: var(--space12X)
}

.fs-16X {
    font-size: var(--space16X)
}

.fs-24X {
    font-size: var(--space24X)
}

.fs-32X {
    font-size: var(--space32X)
}


@media(max-width: 1400px) {
    .xl-n-displayBlock {
        display: block
    }

    .xl-n-displayInlineBlock {
        display: inline-block;
    }

    .xl-n-displayNone {
        display: none
    }

    .xl-n-displayFlex {
        display: flex;
    }

    .xl-n-justifyContentCenter {
        justify-content: center;
    }

    .xl-n-alignItemsCenter {
        align-items: center;
    }

    .xl-n-flexWrap {
        flex-wrap: wrap;
    }

    .xl-n-flexNoWrap {
        flex-wrap: nowrap;
    }

    .xl-n-flexDirectionRow {
        flex-direction: row;
    }

    .xl-n-flexDirectionCol {
        flex-direction: column;
    }

    .xl-n-flexRowReverse {
        flex-direction: row-reverse;
    }

    .xl-n-flexColReverse {
        flex-direction: column-reverse;
    }

    .xl-n-textLeft {
        text-align: left;
    }

    .xl-n-textRight {
        text-align: right;
    }

    .xl-n-textCenter {
        text-align: center;
    }

    .xl-n-widthFull {
        width: 100%
    }

    .xl-n-maxWidthFull {
        max-width: 100%;
    }

    .xl-n-widthAuto {
        width: auto
    }

    .xl-w-188x {
        width: 188px
    }

    .xl-ma-0X {
        margin: 0px
    }

    .xl-ml-0X {
        margin-left: 0px
    }

    .xl-mr-0X {
        margin-right: 0px
    }

    .xl-mt-0X {
        margin-top: 0px
    }

    .xl-mb-0X {
        margin-bottom: 0px
    }

    .xl-mh-0X {
        margin-left: 0px;
        margin-right: 0px
    }

    .xl-mv-0X {
        margin-top: 0px;
        margin-bottom: 0px
    }

    .xl-ma-auto {
        margin: auto
    }

    .xl-mt-auto {
        margin-top: auto
    }

    .xl-mb-auto {
        margin-bottom: auto
    }

    .xl-ml-auto {
        margin-left: auto
    }

    .xl-mr-auto {
        margin-right: auto
    }

    .xl-mh-auto {
        margin-left: auto;
        margin-right: auto
    }

    .xl-mv-auto {
        margin-top: auto;
        margin-bottom: auto
    }

    .xl-mh-0-auto {
        margin: 0px auto
    }

    .xl-mv-0-auto {
        margin: auto 0px
    }

    .xl-pa-0X {
        padding: 0px
    }

    .xl-pl-0X {
        padding-left: 0px
    }

    .xl-pr-0X {
        padding-right: 0px
    }

    .xl-pt-0X {
        padding-top: 0px
    }

    .xl-pb-0X {
        padding-bottom: 0px
    }

    .xl-ph-0X {
        padding-left: 0px;
        padding-right: 0px
    }

    .xl-pv-0X {
        padding-top: 0px;
        padding-bottom: 0px
    }

    .xl-ma-1X {
        margin: var(--space1X)
    }

    .xl-mt-1X {
        margin-top: var(--space1X)
    }

    .xl-mb-1X {
        margin-bottom: var(--space1X)
    }

    .xl-ml-1X {
        margin-left: var(--space1X)
    }

    .xl-mr-1X {
        margin-right: var(--space1X)
    }

    .xl-mv-1X {
        margin-top: var(--space1X);
        margin-bottom: var(--space1X)
    }

    .xl-mh-1X {
        margin-left: var(--space1X);
        margin-right: var(--space1X)
    }

    .xl-ma-2X {
        margin: var(--space2X)
    }

    .xl-mt-2X {
        margin-top: var(--space2X)
    }

    .xl-mb-2X {
        margin-bottom: var(--space2X)
    }

    .xl-ml-2X {
        margin-left: var(--space2X)
    }

    .xl-mr-2X {
        margin-right: var(--space2X)
    }

    .xl-mv-2X {
        margin-top: var(--space2X);
        margin-bottom: var(--space2X)
    }

    .xl-mh-2X {
        margin-left: var(--space2X);
        margin-right: var(--space2X)
    }

    .xl-ma-3X {
        margin: var(--space3X)
    }

    .xl-mt-3X {
        margin-top: var(--space3X)
    }

    .xl-mb-3X {
        margin-bottom: var(--space3X)
    }

    .xl-ml-3X {
        margin-left: var(--space3X)
    }

    .xl-mr-3X {
        margin-right: var(--space3X)
    }

    .xl-mv-3X {
        margin-top: var(--space3X);
        margin-bottom: var(--space3X)
    }

    .xl-mh-3X {
        margin-left: var(--space3X);
        margin-right: var(--space3X)
    }

    .xl-ma-4X {
        margin: var(--space4X)
    }

    .xl-mt-4X {
        margin-top: var(--space4X)
    }

    .xl-mb-4X {
        margin-bottom: var(--space4X)
    }

    .xl-ml-4X {
        margin-left: var(--space4X)
    }

    .xl-mr-4X {
        margin-right: var(--space4X)
    }

    .xl-mv-4X {
        margin-top: var(--space4X);
        margin-bottom: var(--space4X)
    }

    .xl-mh-4X {
        margin-left: var(--space4X);
        margin-right: var(--space4X)
    }

    .xl-ma-5X {
        margin: var(--space5X)
    }

    .xl-mt-5X {
        margin-top: var(--space5X)
    }

    .xl-mb-5X {
        margin-bottom: var(--space5X)
    }

    .xl-ml-5X {
        margin-left: var(--space5X)
    }

    .xl-mr-5X {
        margin-right: var(--space5X)
    }

    .xl-mv-5X {
        margin-top: var(--space5X);
        margin-bottom: var(--space5X)
    }

    .xl-mh-5X {
        margin-left: var(--space5X);
        margin-right: var(--space5X)
    }

    .xl-ma-6X {
        margin: var(--space6X)
    }

    .xl-mt-6X {
        margin-top: var(--space6X)
    }

    .xl-mb-6X {
        margin-bottom: var(--space6X)
    }

    .xl-ml-6X {
        margin-left: var(--space6X)
    }

    .xl-mr-6X {
        margin-right: var(--space6X)
    }

    .xl-mv-6X {
        margin-top: var(--space6X);
        margin-bottom: var(--space6X)
    }

    .xl-mh-6X {
        margin-left: var(--space6X);
        margin-right: var(--space6X)
    }

    .xl-ma-8X {
        margin: var(--space8X)
    }

    .xl-mt-8X {
        margin-top: var(--space8X)
    }

    .xl-mb-8X {
        margin-bottom: var(--space8X)
    }

    .xl-ml-8X {
        margin-left: var(--space8X)
    }

    .xl-mr-8X {
        margin-right: var(--space8X)
    }

    .xl-mv-8X {
        margin-top: var(--space8X);
        margin-bottom: var(--space8X)
    }

    .xl-mh-8X {
        margin-left: var(--space8X);
        margin-right: var(--space8X)
    }

    .xl-ma-12X {
        margin: var(--space12X)
    }

    .xl-mt-12X {
        margin-top: var(--space12X)
    }

    .xl-mb-12X {
        margin-bottom: var(--space12X)
    }

    .xl-ml-12X {
        margin-left: var(--space12X)
    }

    .xl-mr-12X {
        margin-right: var(--space12X)
    }

    .xl-mv-12X {
        margin-top: var(--space12X);
        margin-bottom: var(--space12X)
    }

    .xl-mh-12X {
        margin-left: var(--space12X);
        margin-right: var(--space12X)
    }

    .xl-ma-16X {
        margin: var(--space16X)
    }

    .xl-mt-16X {
        margin-top: var(--space16X)
    }

    .xl-mb-16X {
        margin-bottom: var(--space16X)
    }

    .xl-ml-16X {
        margin-left: var(--space16X)
    }

    .xl-mr-16X {
        margin-right: var(--space16X)
    }

    .xl-mv-16X {
        margin-top: var(--space16X);
        margin-bottom: var(--space16X)
    }

    .xl-mh-16X {
        margin-left: var(--space16X);
        margin-right: var(--space16X)
    }

    .xl-ma-24X {
        margin: var(--space24X)
    }

    .xl-mt-24X {
        margin-top: var(--space24X)
    }

    .xl-mb-24X {
        margin-bottom: var(--space24X)
    }

    .xl-ml-24X {
        margin-left: var(--space24X)
    }

    .xl-mr-24X {
        margin-right: var(--space24X)
    }

    .xl-mv-24X {
        margin-top: var(--space24X);
        margin-bottom: var(--space24X)
    }

    .xl-mh-24X {
        margin-left: var(--space24X);
        margin-right: var(--space24X)
    }

    .xl-ma-32X {
        margin: var(--space32X)
    }

    .xl-mt-32X {
        margin-top: var(--space32X)
    }

    .xl-mb-32X {
        margin-bottom: var(--space32X)
    }

    .xl-ml-32X {
        margin-left: var(--space32X)
    }

    .xl-mr-32X {
        margin-right: var(--space32X)
    }

    .xl-mv-32X {
        margin-top: var(--space32X);
        margin-bottom: var(--space32X)
    }

    .xl-mh-32X {
        margin-left: var(--space32X);
        margin-right: var(--space32X)
    }

    .xl-pa-1X {
        padding: var(--space1X)
    }

    .xl-pt-1X {
        padding-top: var(--space1X)
    }

    .xl-pb-1X {
        padding-bottom: var(--space1X)
    }

    .xl-pl-1X {
        padding-left: var(--space1X)
    }

    .xl-pr-1X {
        padding-right: var(--space1X)
    }

    .xl-pv-1X {
        padding-top: var(--space1X);
        padding-bottom: var(--space1X)
    }

    .xl-ph-1X {
        padding-left: var(--space1X);
        padding-right: var(--space1X)
    }

    .xl-pa-2X {
        padding: var(--space2X)
    }

    .xl-pt-2X {
        padding-top: var(--space2X)
    }

    .xl-pb-2X {
        padding-bottom: var(--space2X)
    }

    .xl-pl-2X {
        padding-left: var(--space2X)
    }

    .xl-pr-2X {
        padding-right: var(--space2X)
    }

    .xl-pv-2X {
        padding-top: var(--space2X);
        padding-bottom: var(--space2X)
    }

    .xl-ph-2X {
        padding-left: var(--space2X);
        padding-right: var(--space2X)
    }

    .xl-pa-3X {
        padding: var(--space3X)
    }

    .xl-pt-3X {
        padding-top: var(--space3X)
    }

    .xl-pb-3X {
        padding-bottom: var(--space3X)
    }

    .xl-pl-3X {
        padding-left: var(--space3X)
    }

    .xl-pr-3X {
        padding-right: var(--space3X)
    }

    .xl-pv-3X {
        padding-top: var(--space3X);
        padding-bottom: var(--space3X)
    }

    .xl-ph-3X {
        padding-left: var(--space3X);
        padding-right: var(--space3X)
    }

    .xl-pa-4X {
        padding: var(--space4X)
    }

    .xl-pt-4X {
        padding-top: var(--space4X)
    }

    .xl-pb-4X {
        padding-bottom: var(--space4X)
    }

    .xl-pl-4X {
        padding-left: var(--space4X)
    }

    .xl-pr-4X {
        padding-right: var(--space4X)
    }

    .xl-pv-4X {
        padding-top: var(--space4X);
        padding-bottom: var(--space4X)
    }

    .xl-ph-4X {
        padding-left: var(--space4X);
        padding-right: var(--space4X)
    }

    .xl-pa-5X {
        padding: var(--space5X)
    }

    .xl-pt-5X {
        padding-top: var(--space5X)
    }

    .xl-pb-5X {
        padding-bottom: var(--space5X)
    }

    .xl-pl-5X {
        padding-left: var(--space5X)
    }

    .xl-pr-5X {
        padding-right: var(--space5X)
    }

    .xl-pv-5X {
        padding-top: var(--space5X);
        padding-bottom: var(--space5X)
    }

    .xl-ph-5X {
        padding-left: var(--space5X);
        padding-right: var(--space5X)
    }

    .xl-pa-6X {
        padding: var(--space6X)
    }

    .xl-pt-6X {
        padding-top: var(--space6X)
    }

    .xl-pb-6X {
        padding-bottom: var(--space6X)
    }

    .xl-pl-6X {
        padding-left: var(--space6X)
    }

    .xl-pr-6X {
        padding-right: var(--space6X)
    }

    .xl-pv-6X {
        padding-top: var(--space6X);
        padding-bottom: var(--space6X)
    }

    .xl-ph-6X {
        padding-left: var(--space6X);
        padding-right: var(--space6X)
    }

    .xl-pa-8X {
        padding: var(--space8X)
    }

    .xl-pt-8X {
        padding-top: var(--space8X)
    }

    .xl-pb-8X {
        padding-bottom: var(--space8X)
    }

    .xl-pl-8X {
        padding-left: var(--space8X)
    }

    .xl-pr-8X {
        padding-right: var(--space8X)
    }

    .xl-pv-8X {
        padding-top: var(--space8X);
        padding-bottom: var(--space8X)
    }

    .xl-ph-8X {
        padding-left: var(--space8X);
        padding-right: var(--space8X)
    }

    .xl-pa-12X {
        padding: var(--space12X)
    }

    .xl-pt-12X {
        padding-top: var(--space12X)
    }

    .xl-pb-12X {
        padding-bottom: var(--space12X)
    }

    .xl-pl-12X {
        padding-left: var(--space12X)
    }

    .xl-pr-12X {
        padding-right: var(--space12X)
    }

    .xl-pv-12X {
        padding-top: var(--space12X);
        padding-bottom: var(--space12X)
    }

    .xl-ph-12X {
        padding-left: var(--space12X);
        padding-right: var(--space12X)
    }

    .xl-pa-16X {
        padding: var(--space16X)
    }

    .xl-pt-16X {
        padding-top: var(--space16X)
    }

    .xl-pb-16X {
        padding-bottom: var(--space16X)
    }

    .xl-pl-16X {
        padding-left: var(--space16X)
    }

    .xl-pr-16X {
        padding-right: var(--space16X)
    }

    .xl-pv-16X {
        padding-top: var(--space16X);
        padding-bottom: var(--space16X)
    }

    .xl-ph-16X {
        padding-left: var(--space16X);
        padding-right: var(--space16X)
    }

    .xl-pa-24X {
        padding: var(--space24X)
    }

    .xl-pt-24X {
        padding-top: var(--space24X)
    }

    .xl-pb-24X {
        padding-bottom: var(--space24X)
    }

    .xl-pl-24X {
        padding-left: var(--space24X)
    }

    .xl-pr-24X {
        padding-right: var(--space24X)
    }

    .xl-pv-24X {
        padding-top: var(--space24X);
        padding-bottom: var(--space24X)
    }

    .xl-ph-24X {
        padding-left: var(--space24X);
        padding-right: var(--space24X)
    }

    .xl-pa-32X {
        padding: var(--space32X)
    }

    .xl-pt-32X {
        padding-top: var(--space32X)
    }

    .xl-pb-32X {
        padding-bottom: var(--space32X)
    }

    .xl-pl-32X {
        padding-left: var(--space32X)
    }

    .xl-pr-32X {
        padding-right: var(--space32X)
    }

    .xl-pv-32X {
        padding-top: var(--space32X);
        padding-bottom: var(--space32X)
    }

    .xl-ph-32X {
        padding-left: var(--space32X);
        padding-right: var(--space32X)
    }

    .xl-lh-1X {
        line-height: var(--space1X)
    }

    .xl-lh-2X {
        line-height: var(--space2X)
    }

    .xl-lh-3X {
        line-height: var(--space3X)
    }

    .xl-lh-4X {
        line-height: var(--space4X)
    }

    .xl-lh-5X {
        line-height: var(--space5X)
    }

    .xl-lh-6X {
        line-height: var(--space6X)
    }

    .xl-lh-8X {
        line-height: var(--space8X)
    }

    .xl-lh-12X {
        line-height: var(--space12X)
    }

    .xl-lh-16X {
        line-height: var(--space16X)
    }

    .xl-lh-24X {
        line-height: var(--space24X)
    }

    .xl-lh-32X {
        line-height: var(--space32X)
    }

    .xl-fs-1X {
        font-size: var(--space1X)
    }

    .xl-fs-2X {
        font-size: var(--space2X)
    }

    .xl-fs-3X {
        font-size: var(--space3X)
    }

    .xl-fs-4X {
        font-size: var(--space4X)
    }

    .xl-fs-5X {
        font-size: var(--space5X)
    }

    .xl-fs-6X {
        font-size: var(--space6X)
    }

    .xl-fs-8X {
        font-size: var(--space8X)
    }

    .xl-fs-12X {
        font-size: var(--space12X)
    }

    .xl-fs-16X {
        font-size: var(--space16X)
    }

    .xl-fs-24X {
        font-size: var(--space24X)
    }

    .xl-fs-32X {
        font-size: var(--space32X)
    }
}

@media(max-width: 1200px) {
    .lg-n-displayBlock {
        display: block
    }

    .lg-n-displayInlineBlock {
        display: inline-block;
    }

    .lg-n-displayNone {
        display: none
    }

    .lg-n-displayFlex {
        display: flex;
    }

    .lg-n-justifyContentCenter {
        justify-content: center;
    }

    .lg-n-alignItemsCenter {
        align-items: center;
    }

    .lg-n-flexWrap {
        flex-wrap: wrap;
    }

    .lg-n-flexNoWrap {
        flex-wrap: nowrap;
    }

    .lg-n-flexDirectionRow {
        flex-direction: row;
    }

    .lg-n-flexDirectionCol {
        flex-direction: column;
    }

    .lg-n-flexRowReverse {
        flex-direction: row-reverse;
    }

    .lg-n-flexColReverse {
        flex-direction: column-reverse;
    }

    .lg-n-textLeft {
        text-align: left;
    }

    .lg-n-textRight {
        text-align: right;
    }

    .lg-n-textCenter {
        text-align: center;
    }


    .lg-n-widthFull {
        width: 100%
    }

    .lg-n-maxWidthFull {
        max-width: 100%;
    }

    .lg-n-widthAuto {
        width: auto
    }

    .lg-w-188x {
        width: 188px
    }

    .lg-ma-0X {
        margin: 0px
    }

    .lg-ml-0X {
        margin-left: 0px
    }

    .lg-mr-0X {
        margin-right: 0px
    }

    .lg-mt-0X {
        margin-top: 0px
    }

    .lg-mb-0X {
        margin-bottom: 0px
    }

    .lg-mh-0X {
        margin-left: 0px;
        margin-right: 0px
    }

    .lg-mv-0X {
        margin-top: 0px;
        margin-bottom: 0px
    }

    .lg-ma-auto {
        margin: auto
    }

    .lg-mt-auto {
        margin-top: auto
    }

    .lg-mb-auto {
        margin-bottom: auto
    }

    .lg-ml-auto {
        margin-left: auto
    }

    .lg-mr-auto {
        margin-right: auto
    }

    .lg-mh-auto {
        margin-left: auto;
        margin-right: auto
    }

    .lg-mv-auto {
        margin-top: auto;
        margin-bottom: auto
    }

    .lg-mh-0-auto {
        margin: 0px auto
    }

    .lg-mv-0-auto {
        margin: auto 0px
    }

    .lg-pa-0X {
        padding: 0px
    }

    .lg-pl-0X {
        padding-left: 0px
    }

    .lg-pr-0X {
        padding-right: 0px
    }

    .lg-pt-0X {
        padding-top: 0px
    }

    .lg-pb-0X {
        padding-bottom: 0px
    }

    .lg-ph-0X {
        padding-left: 0px;
        padding-right: 0px
    }

    .lg-pv-0X {
        padding-top: 0px;
        padding-bottom: 0px
    }

    .lg-ma-1X {
        margin: var(--space1X)
    }

    .lg-mt-1X {
        margin-top: var(--space1X)
    }

    .lg-mb-1X {
        margin-bottom: var(--space1X)
    }

    .lg-ml-1X {
        margin-left: var(--space1X)
    }

    .lg-mr-1X {
        margin-right: var(--space1X)
    }

    .lg-mv-1X {
        margin-top: var(--space1X);
        margin-bottom: var(--space1X)
    }

    .lg-mh-1X {
        margin-left: var(--space1X);
        margin-right: var(--space1X)
    }

    .lg-ma-2X {
        margin: var(--space2X)
    }

    .lg-mt-2X {
        margin-top: var(--space2X)
    }

    .lg-mb-2X {
        margin-bottom: var(--space2X)
    }

    .lg-ml-2X {
        margin-left: var(--space2X)
    }

    .lg-mr-2X {
        margin-right: var(--space2X)
    }

    .lg-mv-2X {
        margin-top: var(--space2X);
        margin-bottom: var(--space2X)
    }

    .lg-mh-2X {
        margin-left: var(--space2X);
        margin-right: var(--space2X)
    }

    .lg-ma-3X {
        margin: var(--space3X)
    }

    .lg-mt-3X {
        margin-top: var(--space3X)
    }

    .lg-mb-3X {
        margin-bottom: var(--space3X)
    }

    .lg-ml-3X {
        margin-left: var(--space3X)
    }

    .lg-mr-3X {
        margin-right: var(--space3X)
    }

    .lg-mv-3X {
        margin-top: var(--space3X);
        margin-bottom: var(--space3X)
    }

    .lg-mh-3X {
        margin-left: var(--space3X);
        margin-right: var(--space3X)
    }

    .lg-ma-4X {
        margin: var(--space4X)
    }

    .lg-mt-4X {
        margin-top: var(--space4X)
    }

    .lg-mb-4X {
        margin-bottom: var(--space4X)
    }

    .lg-ml-4X {
        margin-left: var(--space4X)
    }

    .lg-mr-4X {
        margin-right: var(--space4X)
    }

    .lg-mv-4X {
        margin-top: var(--space4X);
        margin-bottom: var(--space4X)
    }

    .lg-mh-4X {
        margin-left: var(--space4X);
        margin-right: var(--space4X)
    }

    .lg-ma-5X {
        margin: var(--space5X)
    }

    .lg-mt-5X {
        margin-top: var(--space5X)
    }

    .lg-mb-5X {
        margin-bottom: var(--space5X)
    }

    .lg-ml-5X {
        margin-left: var(--space5X)
    }

    .lg-mr-5X {
        margin-right: var(--space5X)
    }

    .lg-mv-5X {
        margin-top: var(--space5X);
        margin-bottom: var(--space5X)
    }

    .lg-mh-5X {
        margin-left: var(--space5X);
        margin-right: var(--space5X)
    }

    .lg-ma-6X {
        margin: var(--space6X)
    }

    .lg-mt-6X {
        margin-top: var(--space6X)
    }

    .lg-mb-6X {
        margin-bottom: var(--space6X)
    }

    .lg-ml-6X {
        margin-left: var(--space6X)
    }

    .lg-mr-6X {
        margin-right: var(--space6X)
    }

    .lg-mv-6X {
        margin-top: var(--space6X);
        margin-bottom: var(--space6X)
    }

    .lg-mh-6X {
        margin-left: var(--space6X);
        margin-right: var(--space6X)
    }

    .lg-ma-8X {
        margin: var(--space8X)
    }

    .lg-mt-8X {
        margin-top: var(--space8X)
    }

    .lg-mb-8X {
        margin-bottom: var(--space8X)
    }

    .lg-ml-8X {
        margin-left: var(--space8X)
    }

    .lg-mr-8X {
        margin-right: var(--space8X)
    }

    .lg-mv-8X {
        margin-top: var(--space8X);
        margin-bottom: var(--space8X)
    }

    .lg-mh-8X {
        margin-left: var(--space8X);
        margin-right: var(--space8X)
    }

    .lg-ma-12X {
        margin: var(--space12X)
    }

    .lg-mt-12X {
        margin-top: var(--space12X)
    }

    .lg-mb-12X {
        margin-bottom: var(--space12X)
    }

    .lg-ml-12X {
        margin-left: var(--space12X)
    }

    .lg-mr-12X {
        margin-right: var(--space12X)
    }

    .lg-mv-12X {
        margin-top: var(--space12X);
        margin-bottom: var(--space12X)
    }

    .lg-mh-12X {
        margin-left: var(--space12X);
        margin-right: var(--space12X)
    }

    .lg-ma-16X {
        margin: var(--space16X)
    }

    .lg-mt-16X {
        margin-top: var(--space16X)
    }

    .lg-mb-16X {
        margin-bottom: var(--space16X)
    }

    .lg-ml-16X {
        margin-left: var(--space16X)
    }

    .lg-mr-16X {
        margin-right: var(--space16X)
    }

    .lg-mv-16X {
        margin-top: var(--space16X);
        margin-bottom: var(--space16X)
    }

    .lg-mh-16X {
        margin-left: var(--space16X);
        margin-right: var(--space16X)
    }

    .lg-ma-24X {
        margin: var(--space24X)
    }

    .lg-mt-24X {
        margin-top: var(--space24X)
    }

    .lg-mb-24X {
        margin-bottom: var(--space24X)
    }

    .lg-ml-24X {
        margin-left: var(--space24X)
    }

    .lg-mr-24X {
        margin-right: var(--space24X)
    }

    .lg-mv-24X {
        margin-top: var(--space24X);
        margin-bottom: var(--space24X)
    }

    .lg-mh-24X {
        margin-left: var(--space24X);
        margin-right: var(--space24X)
    }

    .lg-ma-32X {
        margin: var(--space32X)
    }

    .lg-mt-32X {
        margin-top: var(--space32X)
    }

    .lg-mb-32X {
        margin-bottom: var(--space32X)
    }

    .lg-ml-32X {
        margin-left: var(--space32X)
    }

    .lg-mr-32X {
        margin-right: var(--space32X)
    }

    .lg-mv-32X {
        margin-top: var(--space32X);
        margin-bottom: var(--space32X)
    }

    .lg-mh-32X {
        margin-left: var(--space32X);
        margin-right: var(--space32X)
    }

    .lg-pa-1X {
        padding: var(--space1X)
    }

    .lg-pt-1X {
        padding-top: var(--space1X)
    }

    .lg-pb-1X {
        padding-bottom: var(--space1X)
    }

    .lg-pl-1X {
        padding-left: var(--space1X)
    }

    .lg-pr-1X {
        padding-right: var(--space1X)
    }

    .lg-pv-1X {
        padding-top: var(--space1X);
        padding-bottom: var(--space1X)
    }

    .lg-ph-1X {
        padding-left: var(--space1X);
        padding-right: var(--space1X)
    }

    .lg-pa-2X {
        padding: var(--space2X)
    }

    .lg-pt-2X {
        padding-top: var(--space2X)
    }

    .lg-pb-2X {
        padding-bottom: var(--space2X)
    }

    .lg-pl-2X {
        padding-left: var(--space2X)
    }

    .lg-pr-2X {
        padding-right: var(--space2X)
    }

    .lg-pv-2X {
        padding-top: var(--space2X);
        padding-bottom: var(--space2X)
    }

    .lg-ph-2X {
        padding-left: var(--space2X);
        padding-right: var(--space2X)
    }

    .lg-pa-3X {
        padding: var(--space3X)
    }

    .lg-pt-3X {
        padding-top: var(--space3X)
    }

    .lg-pb-3X {
        padding-bottom: var(--space3X)
    }

    .lg-pl-3X {
        padding-left: var(--space3X)
    }

    .lg-pr-3X {
        padding-right: var(--space3X)
    }

    .lg-pv-3X {
        padding-top: var(--space3X);
        padding-bottom: var(--space3X)
    }

    .lg-ph-3X {
        padding-left: var(--space3X);
        padding-right: var(--space3X)
    }

    .lg-pa-4X {
        padding: var(--space4X)
    }

    .lg-pt-4X {
        padding-top: var(--space4X)
    }

    .lg-pb-4X {
        padding-bottom: var(--space4X)
    }

    .lg-pl-4X {
        padding-left: var(--space4X)
    }

    .lg-pr-4X {
        padding-right: var(--space4X)
    }

    .lg-pv-4X {
        padding-top: var(--space4X);
        padding-bottom: var(--space4X)
    }

    .lg-ph-4X {
        padding-left: var(--space4X);
        padding-right: var(--space4X)
    }

    .lg-pa-5X {
        padding: var(--space5X)
    }

    .lg-pt-5X {
        padding-top: var(--space5X)
    }

    .lg-pb-5X {
        padding-bottom: var(--space5X)
    }

    .lg-pl-5X {
        padding-left: var(--space5X)
    }

    .lg-pr-5X {
        padding-right: var(--space5X)
    }

    .lg-pv-5X {
        padding-top: var(--space5X);
        padding-bottom: var(--space5X)
    }

    .lg-ph-5X {
        padding-left: var(--space5X);
        padding-right: var(--space5X)
    }

    .lg-pa-6X {
        padding: var(--space6X)
    }

    .lg-pt-6X {
        padding-top: var(--space6X)
    }

    .lg-pb-6X {
        padding-bottom: var(--space6X)
    }

    .lg-pl-6X {
        padding-left: var(--space6X)
    }

    .lg-pr-6X {
        padding-right: var(--space6X)
    }

    .lg-pv-6X {
        padding-top: var(--space6X);
        padding-bottom: var(--space6X)
    }

    .lg-ph-6X {
        padding-left: var(--space6X);
        padding-right: var(--space6X)
    }

    .lg-pa-8X {
        padding: var(--space8X)
    }

    .lg-pt-8X {
        padding-top: var(--space8X)
    }

    .lg-pb-8X {
        padding-bottom: var(--space8X)
    }

    .lg-pl-8X {
        padding-left: var(--space8X)
    }

    .lg-pr-8X {
        padding-right: var(--space8X)
    }

    .lg-pv-8X {
        padding-top: var(--space8X);
        padding-bottom: var(--space8X)
    }

    .lg-ph-8X {
        padding-left: var(--space8X);
        padding-right: var(--space8X)
    }

    .lg-pa-12X {
        padding: var(--space12X)
    }

    .lg-pt-12X {
        padding-top: var(--space12X)
    }

    .lg-pb-12X {
        padding-bottom: var(--space12X)
    }

    .lg-pl-12X {
        padding-left: var(--space12X)
    }

    .lg-pr-12X {
        padding-right: var(--space12X)
    }

    .lg-pv-12X {
        padding-top: var(--space12X);
        padding-bottom: var(--space12X)
    }

    .lg-ph-12X {
        padding-left: var(--space12X);
        padding-right: var(--space12X)
    }

    .lg-pa-16X {
        padding: var(--space16X)
    }

    .lg-pt-16X {
        padding-top: var(--space16X)
    }

    .lg-pb-16X {
        padding-bottom: var(--space16X)
    }

    .lg-pl-16X {
        padding-left: var(--space16X)
    }

    .lg-pr-16X {
        padding-right: var(--space16X)
    }

    .lg-pv-16X {
        padding-top: var(--space16X);
        padding-bottom: var(--space16X)
    }

    .lg-ph-16X {
        padding-left: var(--space16X);
        padding-right: var(--space16X)
    }

    .lg-pa-24X {
        padding: var(--space24X)
    }

    .lg-pt-24X {
        padding-top: var(--space24X)
    }

    .lg-pb-24X {
        padding-bottom: var(--space24X)
    }

    .lg-pl-24X {
        padding-left: var(--space24X)
    }

    .lg-pr-24X {
        padding-right: var(--space24X)
    }

    .lg-pv-24X {
        padding-top: var(--space24X);
        padding-bottom: var(--space24X)
    }

    .lg-ph-24X {
        padding-left: var(--space24X);
        padding-right: var(--space24X)
    }

    .lg-pa-32X {
        padding: var(--space32X)
    }

    .lg-pt-32X {
        padding-top: var(--space32X)
    }

    .lg-pb-32X {
        padding-bottom: var(--space32X)
    }

    .lg-pl-32X {
        padding-left: var(--space32X)
    }

    .lg-pr-32X {
        padding-right: var(--space32X)
    }

    .lg-pv-32X {
        padding-top: var(--space32X);
        padding-bottom: var(--space32X)
    }

    .lg-ph-32X {
        padding-left: var(--space32X);
        padding-right: var(--space32X)
    }

    .lg-lh-1X {
        line-height: var(--space1X)
    }

    .lg-lh-2X {
        line-height: var(--space2X)
    }

    .lg-lh-3X {
        line-height: var(--space3X)
    }

    .lg-lh-4X {
        line-height: var(--space4X)
    }

    .lg-lh-5X {
        line-height: var(--space5X)
    }

    .lg-lh-6X {
        line-height: var(--space6X)
    }

    .lg-lh-8X {
        line-height: var(--space8X)
    }

    .lg-lh-12X {
        line-height: var(--space12X)
    }

    .lg-lh-16X {
        line-height: var(--space16X)
    }

    .lg-lh-24X {
        line-height: var(--space24X)
    }

    .lg-lh-32X {
        line-height: var(--space32X)
    }

    .lg-fs-1X {
        font-size: var(--space1X)
    }

    .lg-fs-2X {
        font-size: var(--space2X)
    }

    .lg-fs-3X {
        font-size: var(--space3X)
    }

    .lg-fs-4X {
        font-size: var(--space4X)
    }

    .lg-fs-5X {
        font-size: var(--space5X)
    }

    .lg-fs-6X {
        font-size: var(--space6X)
    }

    .lg-fs-8X {
        font-size: var(--space8X)
    }

    .lg-fs-12X {
        font-size: var(--space12X)
    }

    .lg-fs-16X {
        font-size: var(--space16X)
    }

    .lg-fs-24X {
        font-size: var(--space24X)
    }

    .lg-fs-32X {
        font-size: var(--space32X)
    }
}

@media(max-width: 991px) {
    .md-n-displayBlock {
        display: block
    }

    .md-n-displayInlineBlock {
        display: inline-block;
    }

    .md-n-displayNone {
        display: none
    }

    .md-n-displayFlex {
        display: flex;
    }

    .md-n-justifyContentCenter {
        justify-content: center;
    }

    .md-n-alignItemsCenter {
        align-items: center;
    }

    .md-n-flexWrap {
        flex-wrap: wrap;
    }

    .md-n-flexNoWrap {
        flex-wrap: nowrap;
    }

    .md-n-flexDirectionRow {
        flex-direction: row;
    }

    .md-n-flexDirectionCol {
        flex-direction: column;
    }

    .md-n-flexRowReverse {
        flex-direction: row-reverse;
    }

    .md-n-flexColReverse {
        flex-direction: column-reverse;
    }

    .md-n-textLeft {
        text-align: left;
    }

    .md-n-textRight {
        text-align: right;
    }

    .md-n-textCenter {
        text-align: center;
    }

    .md-n-widthFull {
        width: 100%
    }

    .md-n-maxWidthFull {
        max-width: 100%;
    }

    .md-n-widthAuto {
        width: auto
    }

    .md-w-188x {
        width: 188px
    }

    .md-ma-0X {
        margin: 0px
    }

    .md-ml-0X {
        margin-left: 0px
    }

    .md-mr-0X {
        margin-right: 0px
    }

    .md-mt-0X {
        margin-top: 0px
    }

    .md-mb-0X {
        margin-bottom: 0px
    }

    .md-mh-0X {
        margin-left: 0px;
        margin-right: 0px
    }

    .md-mv-0X {
        margin-top: 0px;
        margin-bottom: 0px
    }

    .md-ma-auto {
        margin: auto
    }

    .md-mt-auto {
        margin-top: auto
    }

    .md-mb-auto {
        margin-bottom: auto
    }

    .md-ml-auto {
        margin-left: auto
    }

    .md-mr-auto {
        margin-right: auto
    }

    .md-mh-auto {
        margin-left: auto;
        margin-right: auto
    }

    .md-mv-auto {
        margin-top: auto;
        margin-bottom: auto
    }

    .md-mh-0-auto {
        margin: 0px auto
    }

    .md-mv-0-auto {
        margin: auto 0px
    }

    .md-pa-0X {
        padding: 0px
    }

    .md-pl-0X {
        padding-left: 0px
    }

    .md-pr-0X {
        padding-right: 0px
    }

    .md-pt-0X {
        padding-top: 0px
    }

    .md-pb-0X {
        padding-bottom: 0px
    }

    .md-ph-0X {
        padding-left: 0px;
        padding-right: 0px
    }

    .md-pv-0X {
        padding-top: 0px;
        padding-bottom: 0px
    }

    .md-ma-1X {
        margin: var(--space1X)
    }

    .md-mt-1X {
        margin-top: var(--space1X)
    }

    .md-mb-1X {
        margin-bottom: var(--space1X)
    }

    .md-ml-1X {
        margin-left: var(--space1X)
    }

    .md-mr-1X {
        margin-right: var(--space1X)
    }

    .md-mv-1X {
        margin-top: var(--space1X);
        margin-bottom: var(--space1X)
    }

    .md-mh-1X {
        margin-left: var(--space1X);
        margin-right: var(--space1X)
    }

    .md-ma-2X {
        margin: var(--space2X)
    }

    .md-mt-2X {
        margin-top: var(--space2X)
    }

    .md-mb-2X {
        margin-bottom: var(--space2X)
    }

    .md-ml-2X {
        margin-left: var(--space2X)
    }

    .md-mr-2X {
        margin-right: var(--space2X)
    }

    .md-mv-2X {
        margin-top: var(--space2X);
        margin-bottom: var(--space2X)
    }

    .md-mh-2X {
        margin-left: var(--space2X);
        margin-right: var(--space2X)
    }

    .md-ma-3X {
        margin: var(--space3X)
    }

    .md-mt-3X {
        margin-top: var(--space3X)
    }

    .md-mb-3X {
        margin-bottom: var(--space3X)
    }

    .md-ml-3X {
        margin-left: var(--space3X)
    }

    .md-mr-3X {
        margin-right: var(--space3X)
    }

    .md-mv-3X {
        margin-top: var(--space3X);
        margin-bottom: var(--space3X)
    }

    .md-mh-3X {
        margin-left: var(--space3X);
        margin-right: var(--space3X)
    }

    .md-ma-4X {
        margin: var(--space4X)
    }

    .md-mt-4X {
        margin-top: var(--space4X)
    }

    .md-mb-4X {
        margin-bottom: var(--space4X)
    }

    .md-ml-4X {
        margin-left: var(--space4X)
    }

    .md-mr-4X {
        margin-right: var(--space4X)
    }

    .md-mv-4X {
        margin-top: var(--space4X);
        margin-bottom: var(--space4X)
    }

    .md-mh-4X {
        margin-left: var(--space4X);
        margin-right: var(--space4X)
    }

    .md-ma-5X {
        margin: var(--space5X)
    }

    .md-mt-5X {
        margin-top: var(--space5X)
    }

    .md-mb-5X {
        margin-bottom: var(--space5X)
    }

    .md-ml-5X {
        margin-left: var(--space5X)
    }

    .md-mr-5X {
        margin-right: var(--space5X)
    }

    .md-mv-5X {
        margin-top: var(--space5X);
        margin-bottom: var(--space5X)
    }

    .md-mh-5X {
        margin-left: var(--space5X);
        margin-right: var(--space5X)
    }

    .md-ma-6X {
        margin: var(--space6X)
    }

    .md-mt-6X {
        margin-top: var(--space6X)
    }

    .md-mb-6X {
        margin-bottom: var(--space6X)
    }

    .md-ml-6X {
        margin-left: var(--space6X)
    }

    .md-mr-6X {
        margin-right: var(--space6X)
    }

    .md-mv-6X {
        margin-top: var(--space6X);
        margin-bottom: var(--space6X)
    }

    .md-mh-6X {
        margin-left: var(--space6X);
        margin-right: var(--space6X)
    }

    .md-ma-8X {
        margin: var(--space8X)
    }

    .md-mt-8X {
        margin-top: var(--space8X)
    }

    .md-mb-8X {
        margin-bottom: var(--space8X)
    }

    .md-ml-8X {
        margin-left: var(--space8X)
    }

    .md-mr-8X {
        margin-right: var(--space8X)
    }

    .md-mv-8X {
        margin-top: var(--space8X);
        margin-bottom: var(--space8X)
    }

    .md-mh-8X {
        margin-left: var(--space8X);
        margin-right: var(--space8X)
    }

    .md-ma-12X {
        margin: var(--space12X)
    }

    .md-mt-12X {
        margin-top: var(--space12X)
    }

    .md-mb-12X {
        margin-bottom: var(--space12X)
    }

    .md-ml-12X {
        margin-left: var(--space12X)
    }

    .md-mr-12X {
        margin-right: var(--space12X)
    }

    .md-mv-12X {
        margin-top: var(--space12X);
        margin-bottom: var(--space12X)
    }

    .md-mh-12X {
        margin-left: var(--space12X);
        margin-right: var(--space12X)
    }

    .md-ma-16X {
        margin: var(--space16X)
    }

    .md-mt-16X {
        margin-top: var(--space16X)
    }

    .md-mb-16X {
        margin-bottom: var(--space16X)
    }

    .md-ml-16X {
        margin-left: var(--space16X)
    }

    .md-mr-16X {
        margin-right: var(--space16X)
    }

    .md-mv-16X {
        margin-top: var(--space16X);
        margin-bottom: var(--space16X)
    }

    .md-mh-16X {
        margin-left: var(--space16X);
        margin-right: var(--space16X)
    }

    .md-ma-24X {
        margin: var(--space24X)
    }

    .md-mt-24X {
        margin-top: var(--space24X)
    }

    .md-mb-24X {
        margin-bottom: var(--space24X)
    }

    .md-ml-24X {
        margin-left: var(--space24X)
    }

    .md-mr-24X {
        margin-right: var(--space24X)
    }

    .md-mv-24X {
        margin-top: var(--space24X);
        margin-bottom: var(--space24X)
    }

    .md-mh-24X {
        margin-left: var(--space24X);
        margin-right: var(--space24X)
    }

    .md-ma-32X {
        margin: var(--space32X)
    }

    .md-mt-32X {
        margin-top: var(--space32X)
    }

    .md-mb-32X {
        margin-bottom: var(--space32X)
    }

    .md-ml-32X {
        margin-left: var(--space32X)
    }

    .md-mr-32X {
        margin-right: var(--space32X)
    }

    .md-mv-32X {
        margin-top: var(--space32X);
        margin-bottom: var(--space32X)
    }

    .md-mh-32X {
        margin-left: var(--space32X);
        margin-right: var(--space32X)
    }

    .md-pa-1X {
        padding: var(--space1X)
    }

    .md-pt-1X {
        padding-top: var(--space1X)
    }

    .md-pb-1X {
        padding-bottom: var(--space1X)
    }

    .md-pl-1X {
        padding-left: var(--space1X)
    }

    .md-pr-1X {
        padding-right: var(--space1X)
    }

    .md-pv-1X {
        padding-top: var(--space1X);
        padding-bottom: var(--space1X)
    }

    .md-ph-1X {
        padding-left: var(--space1X);
        padding-right: var(--space1X)
    }

    .md-pa-2X {
        padding: var(--space2X)
    }

    .md-pt-2X {
        padding-top: var(--space2X)
    }

    .md-pb-2X {
        padding-bottom: var(--space2X)
    }

    .md-pl-2X {
        padding-left: var(--space2X)
    }

    .md-pr-2X {
        padding-right: var(--space2X)
    }

    .md-pv-2X {
        padding-top: var(--space2X);
        padding-bottom: var(--space2X)
    }

    .md-ph-2X {
        padding-left: var(--space2X);
        padding-right: var(--space2X)
    }

    .md-pa-3X {
        padding: var(--space3X)
    }

    .md-pt-3X {
        padding-top: var(--space3X)
    }

    .md-pb-3X {
        padding-bottom: var(--space3X)
    }

    .md-pl-3X {
        padding-left: var(--space3X)
    }

    .md-pr-3X {
        padding-right: var(--space3X)
    }

    .md-pv-3X {
        padding-top: var(--space3X);
        padding-bottom: var(--space3X)
    }

    .md-ph-3X {
        padding-left: var(--space3X);
        padding-right: var(--space3X)
    }

    .md-pa-4X {
        padding: var(--space4X)
    }

    .md-pt-4X {
        padding-top: var(--space4X)
    }

    .md-pb-4X {
        padding-bottom: var(--space4X)
    }

    .md-pl-4X {
        padding-left: var(--space4X)
    }

    .md-pr-4X {
        padding-right: var(--space4X)
    }

    .md-pv-4X {
        padding-top: var(--space4X);
        padding-bottom: var(--space4X)
    }

    .md-ph-4X {
        padding-left: var(--space4X);
        padding-right: var(--space4X)
    }

    .md-pa-5X {
        padding: var(--space5X)
    }

    .md-pt-5X {
        padding-top: var(--space5X)
    }

    .md-pb-5X {
        padding-bottom: var(--space5X)
    }

    .md-pl-5X {
        padding-left: var(--space5X)
    }

    .md-pr-5X {
        padding-right: var(--space5X)
    }

    .md-pv-5X {
        padding-top: var(--space5X);
        padding-bottom: var(--space5X)
    }

    .md-ph-5X {
        padding-left: var(--space5X);
        padding-right: var(--space5X)
    }

    .md-pa-6X {
        padding: var(--space6X)
    }

    .md-pt-6X {
        padding-top: var(--space6X)
    }

    .md-pb-6X {
        padding-bottom: var(--space6X)
    }

    .md-pl-6X {
        padding-left: var(--space6X)
    }

    .md-pr-6X {
        padding-right: var(--space6X)
    }

    .md-pv-6X {
        padding-top: var(--space6X);
        padding-bottom: var(--space6X)
    }

    .md-ph-6X {
        padding-left: var(--space6X);
        padding-right: var(--space6X)
    }

    .md-pa-8X {
        padding: var(--space8X)
    }

    .md-pt-8X {
        padding-top: var(--space8X)
    }

    .md-pb-8X {
        padding-bottom: var(--space8X)
    }

    .md-pl-8X {
        padding-left: var(--space8X)
    }

    .md-pr-8X {
        padding-right: var(--space8X)
    }

    .md-pv-8X {
        padding-top: var(--space8X);
        padding-bottom: var(--space8X)
    }

    .md-ph-8X {
        padding-left: var(--space8X);
        padding-right: var(--space8X)
    }

    .md-pa-12X {
        padding: var(--space12X)
    }

    .md-pt-12X {
        padding-top: var(--space12X)
    }

    .md-pb-12X {
        padding-bottom: var(--space12X)
    }

    .md-pl-12X {
        padding-left: var(--space12X)
    }

    .md-pr-12X {
        padding-right: var(--space12X)
    }

    .md-pv-12X {
        padding-top: var(--space12X);
        padding-bottom: var(--space12X)
    }

    .md-ph-12X {
        padding-left: var(--space12X);
        padding-right: var(--space12X)
    }

    .md-pa-16X {
        padding: var(--space16X)
    }

    .md-pt-16X {
        padding-top: var(--space16X)
    }

    .md-pb-16X {
        padding-bottom: var(--space16X)
    }

    .md-pl-16X {
        padding-left: var(--space16X)
    }

    .md-pr-16X {
        padding-right: var(--space16X)
    }

    .md-pv-16X {
        padding-top: var(--space16X);
        padding-bottom: var(--space16X)
    }

    .md-ph-16X {
        padding-left: var(--space16X);
        padding-right: var(--space16X)
    }

    .md-pa-24X {
        padding: var(--space24X)
    }

    .md-pt-24X {
        padding-top: var(--space24X)
    }

    .md-pb-24X {
        padding-bottom: var(--space24X)
    }

    .md-pl-24X {
        padding-left: var(--space24X)
    }

    .md-pr-24X {
        padding-right: var(--space24X)
    }

    .md-pv-24X {
        padding-top: var(--space24X);
        padding-bottom: var(--space24X)
    }

    .md-ph-24X {
        padding-left: var(--space24X);
        padding-right: var(--space24X)
    }

    .md-pa-32X {
        padding: var(--space32X)
    }

    .md-pt-32X {
        padding-top: var(--space32X)
    }

    .md-pb-32X {
        padding-bottom: var(--space32X)
    }

    .md-pl-32X {
        padding-left: var(--space32X)
    }

    .md-pr-32X {
        padding-right: var(--space32X)
    }

    .md-pv-32X {
        padding-top: var(--space32X);
        padding-bottom: var(--space32X)
    }

    .md-ph-32X {
        padding-left: var(--space32X);
        padding-right: var(--space32X)
    }

    .md-lh-1X {
        line-height: var(--space1X)
    }

    .md-lh-2X {
        line-height: var(--space2X)
    }

    .md-lh-3X {
        line-height: var(--space3X)
    }

    .md-lh-4X {
        line-height: var(--space4X)
    }

    .md-lh-5X {
        line-height: var(--space5X)
    }

    .md-lh-6X {
        line-height: var(--space6X)
    }

    .md-lh-8X {
        line-height: var(--space8X)
    }

    .md-lh-12X {
        line-height: var(--space12X)
    }

    .md-lh-16X {
        line-height: var(--space16X)
    }

    .md-lh-24X {
        line-height: var(--space24X)
    }

    .md-lh-32X {
        line-height: var(--space32X)
    }

    .md-fs-1X {
        font-size: var(--space1X)
    }

    .md-fs-2X {
        font-size: var(--space2X)
    }

    .md-fs-3X {
        font-size: var(--space3X)
    }

    .md-fs-4X {
        font-size: var(--space4X)
    }

    .md-fs-5X {
        font-size: var(--space5X)
    }

    .md-fs-6X {
        font-size: var(--space6X)
    }

    .md-fs-8X {
        font-size: var(--space8X)
    }

    .md-fs-12X {
        font-size: var(--space12X)
    }

    .md-fs-16X {
        font-size: var(--space16X)
    }

    .md-fs-24X {
        font-size: var(--space24X)
    }

    .md-fs-32X {
        font-size: var(--space32X)
    }
}

@media(max-width: 767px) {
    .sm-n-displayBlock {
        display: block
    }

    .sm-n-displayInlineBlock {
        display: inline-block;
    }

    .sm-n-displayNone {
        display: none
    }

    .sm-n-displayFlex {
        display: flex;
    }

    .sm-n-justifyContentCenter {
        justify-content: center;
    }

    .sm-n-alignItemsCenter {
        align-items: center;
    }

    .sm-n-flexWrap {
        flex-wrap: wrap;
    }

    .sm-n-flexNoWrap {
        flex-wrap: nowrap;
    }

    .sm-n-flexDirectionRow {
        flex-direction: row;
    }

    .sm-n-flexDirectionCol {
        flex-direction: column;
    }

    .sm-n-flexRowReverse {
        flex-direction: row-reverse;
    }

    .sm-n-flexColReverse {
        flex-direction: column-reverse;
    }

    .sm-n-textLeft {
        text-align: left;
    }

    .sm-n-textRight {
        text-align: right;
    }

    .sm-n-textCenter {
        text-align: center;
    }

    .sm-n-widthFull {
        width: 100%
    }

    .sm-n-maxWidthFull {
        max-width: 100%;
    }

    .sm-n-widthAuto {
        width: auto
    }

    .sm-w-188x {
        width: 188px
    }

    .sm-ma-0X {
        margin: 0px
    }

    .sm-ml-0X {
        margin-left: 0px
    }

    .sm-mr-0X {
        margin-right: 0px
    }

    .sm-mt-0X {
        margin-top: 0px
    }

    .sm-mb-0X {
        margin-bottom: 0px
    }

    .sm-mh-0X {
        margin-left: 0px;
        margin-right: 0px
    }

    .sm-mv-0X {
        margin-top: 0px;
        margin-bottom: 0px
    }

    .sm-ma-auto {
        margin: auto
    }

    .sm-mt-auto {
        margin-top: auto
    }

    .sm-mb-auto {
        margin-bottom: auto
    }

    .sm-ml-auto {
        margin-left: auto
    }

    .sm-mr-auto {
        margin-right: auto
    }

    .sm-mh-auto {
        margin-left: auto;
        margin-right: auto
    }

    .sm-mv-auto {
        margin-top: auto;
        margin-bottom: auto
    }

    .sm-mh-0-auto {
        margin: 0px auto
    }

    .sm-mv-0-auto {
        margin: auto 0px
    }

    .sm-pa-0X {
        padding: 0px
    }

    .sm-pl-0X {
        padding-left: 0px
    }

    .sm-pr-0X {
        padding-right: 0px
    }

    .sm-pt-0X {
        padding-top: 0px
    }

    .sm-pb-0X {
        padding-bottom: 0px
    }

    .sm-ph-0X {
        padding-left: 0px;
        padding-right: 0px
    }

    .sm-pv-0X {
        padding-top: 0px;
        padding-bottom: 0px
    }

    .sm-ma-1X {
        margin: var(--space1X)
    }

    .sm-mt-1X {
        margin-top: var(--space1X)
    }

    .sm-mb-1X {
        margin-bottom: var(--space1X)
    }

    .sm-ml-1X {
        margin-left: var(--space1X)
    }

    .sm-mr-1X {
        margin-right: var(--space1X)
    }

    .sm-mv-1X {
        margin-top: var(--space1X);
        margin-bottom: var(--space1X)
    }

    .sm-mh-1X {
        margin-left: var(--space1X);
        margin-right: var(--space1X)
    }

    .sm-ma-2X {
        margin: var(--space2X)
    }

    .sm-mt-2X {
        margin-top: var(--space2X)
    }

    .sm-mb-2X {
        margin-bottom: var(--space2X)
    }

    .sm-ml-2X {
        margin-left: var(--space2X)
    }

    .sm-mr-2X {
        margin-right: var(--space2X)
    }

    .sm-mv-2X {
        margin-top: var(--space2X);
        margin-bottom: var(--space2X)
    }

    .sm-mh-2X {
        margin-left: var(--space2X);
        margin-right: var(--space2X)
    }

    .sm-ma-3X {
        margin: var(--space3X)
    }

    .sm-mt-3X {
        margin-top: var(--space3X)
    }

    .sm-mb-3X {
        margin-bottom: var(--space3X)
    }

    .sm-ml-3X {
        margin-left: var(--space3X)
    }

    .sm-mr-3X {
        margin-right: var(--space3X)
    }

    .sm-mv-3X {
        margin-top: var(--space3X);
        margin-bottom: var(--space3X)
    }

    .sm-mh-3X {
        margin-left: var(--space3X);
        margin-right: var(--space3X)
    }

    .sm-ma-4X {
        margin: var(--space4X)
    }

    .sm-mt-4X {
        margin-top: var(--space4X)
    }

    .sm-mb-4X {
        margin-bottom: var(--space4X)
    }

    .sm-ml-4X {
        margin-left: var(--space4X)
    }

    .sm-mr-4X {
        margin-right: var(--space4X)
    }

    .sm-mv-4X {
        margin-top: var(--space4X);
        margin-bottom: var(--space4X)
    }

    .sm-mh-4X {
        margin-left: var(--space4X);
        margin-right: var(--space4X)
    }

    .sm-ma-5X {
        margin: var(--space5X)
    }

    .sm-mt-5X {
        margin-top: var(--space5X)
    }

    .sm-mb-5X {
        margin-bottom: var(--space5X)
    }

    .sm-ml-5X {
        margin-left: var(--space5X)
    }

    .sm-mr-5X {
        margin-right: var(--space5X)
    }

    .sm-mv-5X {
        margin-top: var(--space5X);
        margin-bottom: var(--space5X)
    }

    .sm-mh-5X {
        margin-left: var(--space5X);
        margin-right: var(--space5X)
    }

    .sm-ma-6X {
        margin: var(--space6X)
    }

    .sm-mt-6X {
        margin-top: var(--space6X)
    }

    .sm-mb-6X {
        margin-bottom: var(--space6X)
    }

    .sm-ml-6X {
        margin-left: var(--space6X)
    }

    .sm-mr-6X {
        margin-right: var(--space6X)
    }

    .sm-mv-6X {
        margin-top: var(--space6X);
        margin-bottom: var(--space6X)
    }

    .sm-mh-6X {
        margin-left: var(--space6X);
        margin-right: var(--space6X)
    }

    .sm-ma-8X {
        margin: var(--space8X)
    }

    .sm-mt-8X {
        margin-top: var(--space8X)
    }

    .sm-mb-8X {
        margin-bottom: var(--space8X)
    }

    .sm-ml-8X {
        margin-left: var(--space8X)
    }

    .sm-mr-8X {
        margin-right: var(--space8X)
    }

    .sm-mv-8X {
        margin-top: var(--space8X);
        margin-bottom: var(--space8X)
    }

    .sm-mh-8X {
        margin-left: var(--space8X);
        margin-right: var(--space8X)
    }

    .sm-ma-12X {
        margin: var(--space12X)
    }

    .sm-mt-12X {
        margin-top: var(--space12X)
    }

    .sm-mb-12X {
        margin-bottom: var(--space12X)
    }

    .sm-ml-12X {
        margin-left: var(--space12X)
    }

    .sm-mr-12X {
        margin-right: var(--space12X)
    }

    .sm-mv-12X {
        margin-top: var(--space12X);
        margin-bottom: var(--space12X)
    }

    .sm-mh-12X {
        margin-left: var(--space12X);
        margin-right: var(--space12X)
    }

    .sm-ma-16X {
        margin: var(--space16X)
    }

    .sm-mt-16X {
        margin-top: var(--space16X)
    }

    .sm-mb-16X {
        margin-bottom: var(--space16X)
    }

    .sm-ml-16X {
        margin-left: var(--space16X)
    }

    .sm-mr-16X {
        margin-right: var(--space16X)
    }

    .sm-mv-16X {
        margin-top: var(--space16X);
        margin-bottom: var(--space16X)
    }

    .sm-mh-16X {
        margin-left: var(--space16X);
        margin-right: var(--space16X)
    }

    .sm-ma-24X {
        margin: var(--space24X)
    }

    .sm-mt-24X {
        margin-top: var(--space24X)
    }

    .sm-mb-24X {
        margin-bottom: var(--space24X)
    }

    .sm-ml-24X {
        margin-left: var(--space24X)
    }

    .sm-mr-24X {
        margin-right: var(--space24X)
    }

    .sm-mv-24X {
        margin-top: var(--space24X);
        margin-bottom: var(--space24X)
    }

    .sm-mh-24X {
        margin-left: var(--space24X);
        margin-right: var(--space24X)
    }

    .sm-ma-32X {
        margin: var(--space32X)
    }

    .sm-mt-32X {
        margin-top: var(--space32X)
    }

    .sm-mb-32X {
        margin-bottom: var(--space32X)
    }

    .sm-ml-32X {
        margin-left: var(--space32X)
    }

    .sm-mr-32X {
        margin-right: var(--space32X)
    }

    .sm-mv-32X {
        margin-top: var(--space32X);
        margin-bottom: var(--space32X)
    }

    .sm-mh-32X {
        margin-left: var(--space32X);
        margin-right: var(--space32X)
    }

    .sm-pa-1X {
        padding: var(--space1X)
    }

    .sm-pt-1X {
        padding-top: var(--space1X)
    }

    .sm-pb-1X {
        padding-bottom: var(--space1X)
    }

    .sm-pl-1X {
        padding-left: var(--space1X)
    }

    .sm-pr-1X {
        padding-right: var(--space1X)
    }

    .sm-pv-1X {
        padding-top: var(--space1X);
        padding-bottom: var(--space1X)
    }

    .sm-ph-1X {
        padding-left: var(--space1X);
        padding-right: var(--space1X)
    }

    .sm-pa-2X {
        padding: var(--space2X)
    }

    .sm-pt-2X {
        padding-top: var(--space2X)
    }

    .sm-pb-2X {
        padding-bottom: var(--space2X)
    }

    .sm-pl-2X {
        padding-left: var(--space2X)
    }

    .sm-pr-2X {
        padding-right: var(--space2X)
    }

    .sm-pv-2X {
        padding-top: var(--space2X);
        padding-bottom: var(--space2X)
    }

    .sm-ph-2X {
        padding-left: var(--space2X);
        padding-right: var(--space2X)
    }

    .sm-pa-3X {
        padding: var(--space3X)
    }

    .sm-pt-3X {
        padding-top: var(--space3X)
    }

    .sm-pb-3X {
        padding-bottom: var(--space3X)
    }

    .sm-pl-3X {
        padding-left: var(--space3X)
    }

    .sm-pr-3X {
        padding-right: var(--space3X)
    }

    .sm-pv-3X {
        padding-top: var(--space3X);
        padding-bottom: var(--space3X)
    }

    .sm-ph-3X {
        padding-left: var(--space3X);
        padding-right: var(--space3X)
    }

    .sm-pa-4X {
        padding: var(--space4X)
    }

    .sm-pt-4X {
        padding-top: var(--space4X)
    }

    .sm-pb-4X {
        padding-bottom: var(--space4X)
    }

    .sm-pl-4X {
        padding-left: var(--space4X)
    }

    .sm-pr-4X {
        padding-right: var(--space4X)
    }

    .sm-pv-4X {
        padding-top: var(--space4X);
        padding-bottom: var(--space4X)
    }

    .sm-ph-4X {
        padding-left: var(--space4X);
        padding-right: var(--space4X)
    }

    .sm-pa-5X {
        padding: var(--space5X)
    }

    .sm-pt-5X {
        padding-top: var(--space5X)
    }

    .sm-pb-5X {
        padding-bottom: var(--space5X)
    }

    .sm-pl-5X {
        padding-left: var(--space5X)
    }

    .sm-pr-5X {
        padding-right: var(--space5X)
    }

    .sm-pv-5X {
        padding-top: var(--space5X);
        padding-bottom: var(--space5X)
    }

    .sm-ph-5X {
        padding-left: var(--space5X);
        padding-right: var(--space5X)
    }

    .sm-pa-6X {
        padding: var(--space6X)
    }

    .sm-pt-6X {
        padding-top: var(--space6X)
    }

    .sm-pb-6X {
        padding-bottom: var(--space6X)
    }

    .sm-pl-6X {
        padding-left: var(--space6X)
    }

    .sm-pr-6X {
        padding-right: var(--space6X)
    }

    .sm-pv-6X {
        padding-top: var(--space6X);
        padding-bottom: var(--space6X)
    }

    .sm-ph-6X {
        padding-left: var(--space6X);
        padding-right: var(--space6X)
    }

    .sm-pa-8X {
        padding: var(--space8X)
    }

    .sm-pt-8X {
        padding-top: var(--space8X)
    }

    .sm-pb-8X {
        padding-bottom: var(--space8X)
    }

    .sm-pl-8X {
        padding-left: var(--space8X)
    }

    .sm-pr-8X {
        padding-right: var(--space8X)
    }

    .sm-pv-8X {
        padding-top: var(--space8X);
        padding-bottom: var(--space8X)
    }

    .sm-ph-8X {
        padding-left: var(--space8X);
        padding-right: var(--space8X)
    }

    .sm-pa-12X {
        padding: var(--space12X)
    }

    .sm-pt-12X {
        padding-top: var(--space12X)
    }

    .sm-pb-12X {
        padding-bottom: var(--space12X)
    }

    .sm-pl-12X {
        padding-left: var(--space12X)
    }

    .sm-pr-12X {
        padding-right: var(--space12X)
    }

    .sm-pv-12X {
        padding-top: var(--space12X);
        padding-bottom: var(--space12X)
    }

    .sm-ph-12X {
        padding-left: var(--space12X);
        padding-right: var(--space12X)
    }

    .sm-pa-16X {
        padding: var(--space16X)
    }

    .sm-pt-16X {
        padding-top: var(--space16X)
    }

    .sm-pb-16X {
        padding-bottom: var(--space16X)
    }

    .sm-pl-16X {
        padding-left: var(--space16X)
    }

    .sm-pr-16X {
        padding-right: var(--space16X)
    }

    .sm-pv-16X {
        padding-top: var(--space16X);
        padding-bottom: var(--space16X)
    }

    .sm-ph-16X {
        padding-left: var(--space16X);
        padding-right: var(--space16X)
    }

    .sm-pa-24X {
        padding: var(--space24X)
    }

    .sm-pt-24X {
        padding-top: var(--space24X)
    }

    .sm-pb-24X {
        padding-bottom: var(--space24X)
    }

    .sm-pl-24X {
        padding-left: var(--space24X)
    }

    .sm-pr-24X {
        padding-right: var(--space24X)
    }

    .sm-pv-24X {
        padding-top: var(--space24X);
        padding-bottom: var(--space24X)
    }

    .sm-ph-24X {
        padding-left: var(--space24X);
        padding-right: var(--space24X)
    }

    .sm-pa-32X {
        padding: var(--space32X)
    }

    .sm-pt-32X {
        padding-top: var(--space32X)
    }

    .sm-pb-32X {
        padding-bottom: var(--space32X)
    }

    .sm-pl-32X {
        padding-left: var(--space32X)
    }

    .sm-pr-32X {
        padding-right: var(--space32X)
    }

    .sm-pv-32X {
        padding-top: var(--space32X);
        padding-bottom: var(--space32X)
    }

    .sm-ph-32X {
        padding-left: var(--space32X);
        padding-right: var(--space32X)
    }

    .sm-lh-1X {
        line-height: var(--space1X)
    }

    .sm-lh-2X {
        line-height: var(--space2X)
    }

    .sm-lh-3X {
        line-height: var(--space3X)
    }

    .sm-lh-4X {
        line-height: var(--space4X)
    }

    .sm-lh-5X {
        line-height: var(--space5X)
    }

    .sm-lh-6X {
        line-height: var(--space6X)
    }

    .sm-lh-8X {
        line-height: var(--space8X)
    }

    .sm-lh-12X {
        line-height: var(--space12X)
    }

    .sm-lh-16X {
        line-height: var(--space16X)
    }

    .sm-lh-24X {
        line-height: var(--space24X)
    }

    .sm-lh-32X {
        line-height: var(--space32X)
    }

    .sm-fs-1X {
        font-size: var(--space1X)
    }

    .sm-fs-2X {
        font-size: var(--space2X)
    }

    .sm-fs-3X {
        font-size: var(--space3X)
    }

    .sm-fs-4X {
        font-size: var(--space4X)
    }

    .sm-fs-5X {
        font-size: var(--space5X)
    }

    .sm-fs-6X {
        font-size: var(--space6X)
    }

    .sm-fs-8X {
        font-size: var(--space8X)
    }

    .sm-fs-12X {
        font-size: var(--space12X)
    }

    .sm-fs-16X {
        font-size: var(--space16X)
    }

    .sm-fs-24X {
        font-size: var(--space24X)
    }

    .sm-fs-32X {
        font-size: var(--space32X)
    }
}

@media(max-width: 575px) {
    .xs-n-displayBlock {
        display: block
    }

    .xs-n-displayInlineBlock {
        display: inline-block;
    }

    .xs-n-displayNone {
        display: none
    }

    .xs-n-displayFlex {
        display: flex;
    }

    .xs-n-justifyContentCenter {
        justify-content: center;
    }

    .xs-n-alignItemsCenter {
        align-items: center;
    }

    .xs-n-flexWrap {
        flex-wrap: wrap;
    }

    .xs-n-flexNoWrap {
        flex-wrap: nowrap;
    }

    .xs-n-flexDirectionRow {
        flex-direction: row;
    }

    .xs-n-flexDirectionCol {
        flex-direction: column;
    }

    .xs-n-flexRowReverse {
        flex-direction: row-reverse;
    }

    .xs-n-flexColReverse {
        flex-direction: column-reverse;
    }

    .xs-n-textLeft {
        text-align: left;
    }

    .xs-n-textRight {
        text-align: right;
    }

    .xs-n-textCenter {
        text-align: center;
    }

    .xs-n-widthFull {
        width: 100%
    }

    .xs-n-maxWidthFull {
        max-width: 100%;
    }

    .xs-n-widthAuto {
        width: auto
    }

    .xs-w-188x {
        width: 188px
    }

    .xs-ma-0X {
        margin: 0px
    }

    .xs-ml-0X {
        margin-left: 0px
    }

    .xs-mr-0X {
        margin-right: 0px
    }

    .xs-mt-0X {
        margin-top: 0px
    }

    .xs-mb-0X {
        margin-bottom: 0px
    }

    .xs-mh-0X {
        margin-left: 0px;
        margin-right: 0px
    }

    .xs-mv-0X {
        margin-top: 0px;
        margin-bottom: 0px
    }

    .xs-ma-auto {
        margin: auto
    }

    .xs-mt-auto {
        margin-top: auto
    }

    .xs-mb-auto {
        margin-bottom: auto
    }

    .xs-ml-auto {
        margin-left: auto
    }

    .xs-mr-auto {
        margin-right: auto
    }

    .xs-mh-auto {
        margin-left: auto;
        margin-right: auto
    }

    .xs-mv-auto {
        margin-top: auto;
        margin-bottom: auto
    }

    .xs-mh-0-auto {
        margin: 0px auto
    }

    .xs-mv-0-auto {
        margin: auto 0px
    }

    .xs-pa-0X {
        padding: 0px
    }

    .xs-pl-0X {
        padding-left: 0px
    }

    .xs-pr-0X {
        padding-right: 0px
    }

    .xs-pt-0X {
        padding-top: 0px
    }

    .xs-pb-0X {
        padding-bottom: 0px
    }

    .xs-ph-0X {
        padding-left: 0px;
        padding-right: 0px
    }

    .xs-pv-0X {
        padding-top: 0px;
        padding-bottom: 0px
    }

    .xs-ma-1X {
        margin: var(--space1X)
    }

    .xs-mt-1X {
        margin-top: var(--space1X)
    }

    .xs-mb-1X {
        margin-bottom: var(--space1X)
    }

    .xs-ml-1X {
        margin-left: var(--space1X)
    }

    .xs-mr-1X {
        margin-right: var(--space1X)
    }

    .xs-mv-1X {
        margin-top: var(--space1X);
        margin-bottom: var(--space1X)
    }

    .xs-mh-1X {
        margin-left: var(--space1X);
        margin-right: var(--space1X)
    }

    .xs-ma-2X {
        margin: var(--space2X)
    }

    .xs-mt-2X {
        margin-top: var(--space2X)
    }

    .xs-mb-2X {
        margin-bottom: var(--space2X)
    }

    .xs-ml-2X {
        margin-left: var(--space2X)
    }

    .xs-mr-2X {
        margin-right: var(--space2X)
    }

    .xs-mv-2X {
        margin-top: var(--space2X);
        margin-bottom: var(--space2X)
    }

    .xs-mh-2X {
        margin-left: var(--space2X);
        margin-right: var(--space2X)
    }

    .xs-ma-3X {
        margin: var(--space3X)
    }

    .xs-mt-3X {
        margin-top: var(--space3X)
    }

    .xs-mb-3X {
        margin-bottom: var(--space3X)
    }

    .xs-ml-3X {
        margin-left: var(--space3X)
    }

    .xs-mr-3X {
        margin-right: var(--space3X)
    }

    .xs-mv-3X {
        margin-top: var(--space3X);
        margin-bottom: var(--space3X)
    }

    .xs-mh-3X {
        margin-left: var(--space3X);
        margin-right: var(--space3X)
    }

    .xs-ma-4X {
        margin: var(--space4X)
    }

    .xs-mt-4X {
        margin-top: var(--space4X)
    }

    .xs-mb-4X {
        margin-bottom: var(--space4X)
    }

    .xs-ml-4X {
        margin-left: var(--space4X)
    }

    .xs-mr-4X {
        margin-right: var(--space4X)
    }

    .xs-mv-4X {
        margin-top: var(--space4X);
        margin-bottom: var(--space4X)
    }

    .xs-mh-4X {
        margin-left: var(--space4X);
        margin-right: var(--space4X)
    }

    .xs-ma-5X {
        margin: var(--space5X)
    }

    .xs-mt-5X {
        margin-top: var(--space5X)
    }

    .xs-mb-5X {
        margin-bottom: var(--space5X)
    }

    .xs-ml-5X {
        margin-left: var(--space5X)
    }

    .xs-mr-5X {
        margin-right: var(--space5X)
    }

    .xs-mv-5X {
        margin-top: var(--space5X);
        margin-bottom: var(--space5X)
    }

    .xs-mh-5X {
        margin-left: var(--space5X);
        margin-right: var(--space5X)
    }

    .xs-ma-6X {
        margin: var(--space6X)
    }

    .xs-mt-6X {
        margin-top: var(--space6X)
    }

    .xs-mb-6X {
        margin-bottom: var(--space6X)
    }

    .xs-ml-6X {
        margin-left: var(--space6X)
    }

    .xs-mr-6X {
        margin-right: var(--space6X)
    }

    .xs-mv-6X {
        margin-top: var(--space6X);
        margin-bottom: var(--space6X)
    }

    .xs-mh-6X {
        margin-left: var(--space6X);
        margin-right: var(--space6X)
    }

    .xs-ma-8X {
        margin: var(--space8X)
    }

    .xs-mt-8X {
        margin-top: var(--space8X)
    }

    .xs-mb-8X {
        margin-bottom: var(--space8X)
    }

    .xs-ml-8X {
        margin-left: var(--space8X)
    }

    .xs-mr-8X {
        margin-right: var(--space8X)
    }

    .xs-mv-8X {
        margin-top: var(--space8X);
        margin-bottom: var(--space8X)
    }

    .xs-mh-8X {
        margin-left: var(--space8X);
        margin-right: var(--space8X)
    }

    .xs-ma-12X {
        margin: var(--space12X)
    }

    .xs-mt-12X {
        margin-top: var(--space12X)
    }

    .xs-mb-12X {
        margin-bottom: var(--space12X)
    }

    .xs-ml-12X {
        margin-left: var(--space12X)
    }

    .xs-mr-12X {
        margin-right: var(--space12X)
    }

    .xs-mv-12X {
        margin-top: var(--space12X);
        margin-bottom: var(--space12X)
    }

    .xs-mh-12X {
        margin-left: var(--space12X);
        margin-right: var(--space12X)
    }

    .xs-ma-16X {
        margin: var(--space16X)
    }

    .xs-mt-16X {
        margin-top: var(--space16X)
    }

    .xs-mb-16X {
        margin-bottom: var(--space16X)
    }

    .xs-ml-16X {
        margin-left: var(--space16X)
    }

    .xs-mr-16X {
        margin-right: var(--space16X)
    }

    .xs-mv-16X {
        margin-top: var(--space16X);
        margin-bottom: var(--space16X)
    }

    .xs-mh-16X {
        margin-left: var(--space16X);
        margin-right: var(--space16X)
    }

    .xs-ma-24X {
        margin: var(--space24X)
    }

    .xs-mt-24X {
        margin-top: var(--space24X)
    }

    .xs-mb-24X {
        margin-bottom: var(--space24X)
    }

    .xs-ml-24X {
        margin-left: var(--space24X)
    }

    .xs-mr-24X {
        margin-right: var(--space24X)
    }

    .xs-mv-24X {
        margin-top: var(--space24X);
        margin-bottom: var(--space24X)
    }

    .xs-mh-24X {
        margin-left: var(--space24X);
        margin-right: var(--space24X)
    }

    .xs-ma-32X {
        margin: var(--space32X)
    }

    .xs-mt-32X {
        margin-top: var(--space32X)
    }

    .xs-mb-32X {
        margin-bottom: var(--space32X)
    }

    .xs-ml-32X {
        margin-left: var(--space32X)
    }

    .xs-mr-32X {
        margin-right: var(--space32X)
    }

    .xs-mv-32X {
        margin-top: var(--space32X);
        margin-bottom: var(--space32X)
    }

    .xs-mh-32X {
        margin-left: var(--space32X);
        margin-right: var(--space32X)
    }

    .xs-pa-1X {
        padding: var(--space1X)
    }

    .xs-pt-1X {
        padding-top: var(--space1X)
    }

    .xs-pb-1X {
        padding-bottom: var(--space1X)
    }

    .xs-pl-1X {
        padding-left: var(--space1X)
    }

    .xs-pr-1X {
        padding-right: var(--space1X)
    }

    .xs-pv-1X {
        padding-top: var(--space1X);
        padding-bottom: var(--space1X)
    }

    .xs-ph-1X {
        padding-left: var(--space1X);
        padding-right: var(--space1X)
    }

    .xs-pa-2X {
        padding: var(--space2X)
    }

    .xs-pt-2X {
        padding-top: var(--space2X)
    }

    .xs-pb-2X {
        padding-bottom: var(--space2X)
    }

    .xs-pl-2X {
        padding-left: var(--space2X)
    }

    .xs-pr-2X {
        padding-right: var(--space2X)
    }

    .xs-pv-2X {
        padding-top: var(--space2X);
        padding-bottom: var(--space2X)
    }

    .xs-ph-2X {
        padding-left: var(--space2X);
        padding-right: var(--space2X)
    }

    .xs-pa-3X {
        padding: var(--space3X)
    }

    .xs-pt-3X {
        padding-top: var(--space3X)
    }

    .xs-pb-3X {
        padding-bottom: var(--space3X)
    }

    .xs-pl-3X {
        padding-left: var(--space3X)
    }

    .xs-pr-3X {
        padding-right: var(--space3X)
    }

    .xs-pv-3X {
        padding-top: var(--space3X);
        padding-bottom: var(--space3X)
    }

    .xs-ph-3X {
        padding-left: var(--space3X);
        padding-right: var(--space3X)
    }

    .xs-pa-4X {
        padding: var(--space4X)
    }

    .xs-pt-4X {
        padding-top: var(--space4X)
    }

    .xs-pb-4X {
        padding-bottom: var(--space4X)
    }

    .xs-pl-4X {
        padding-left: var(--space4X)
    }

    .xs-pr-4X {
        padding-right: var(--space4X)
    }

    .xs-pv-4X {
        padding-top: var(--space4X);
        padding-bottom: var(--space4X)
    }

    .xs-ph-4X {
        padding-left: var(--space4X);
        padding-right: var(--space4X)
    }

    .xs-pa-5X {
        padding: var(--space5X)
    }

    .xs-pt-5X {
        padding-top: var(--space5X)
    }

    .xs-pb-5X {
        padding-bottom: var(--space5X)
    }

    .xs-pl-5X {
        padding-left: var(--space5X)
    }

    .xs-pr-5X {
        padding-right: var(--space5X)
    }

    .xs-pv-5X {
        padding-top: var(--space5X);
        padding-bottom: var(--space5X)
    }

    .xs-ph-5X {
        padding-left: var(--space5X);
        padding-right: var(--space5X)
    }

    .xs-pa-6X {
        padding: var(--space6X)
    }

    .xs-pt-6X {
        padding-top: var(--space6X)
    }

    .xs-pb-6X {
        padding-bottom: var(--space6X)
    }

    .xs-pl-6X {
        padding-left: var(--space6X)
    }

    .xs-pr-6X {
        padding-right: var(--space6X)
    }

    .xs-pv-6X {
        padding-top: var(--space6X);
        padding-bottom: var(--space6X)
    }

    .xs-ph-6X {
        padding-left: var(--space6X);
        padding-right: var(--space6X)
    }

    .xs-pa-8X {
        padding: var(--space8X)
    }

    .xs-pt-8X {
        padding-top: var(--space8X)
    }

    .xs-pb-8X {
        padding-bottom: var(--space8X)
    }

    .xs-pl-8X {
        padding-left: var(--space8X)
    }

    .xs-pr-8X {
        padding-right: var(--space8X)
    }

    .xs-pv-8X {
        padding-top: var(--space8X);
        padding-bottom: var(--space8X)
    }

    .xs-ph-8X {
        padding-left: var(--space8X);
        padding-right: var(--space8X)
    }

    .xs-pa-12X {
        padding: var(--space12X)
    }

    .xs-pt-12X {
        padding-top: var(--space12X)
    }

    .xs-pb-12X {
        padding-bottom: var(--space12X)
    }

    .xs-pl-12X {
        padding-left: var(--space12X)
    }

    .xs-pr-12X {
        padding-right: var(--space12X)
    }

    .xs-pv-12X {
        padding-top: var(--space12X);
        padding-bottom: var(--space12X)
    }

    .xs-ph-12X {
        padding-left: var(--space12X);
        padding-right: var(--space12X)
    }

    .xs-pa-16X {
        padding: var(--space16X)
    }

    .xs-pt-16X {
        padding-top: var(--space16X)
    }

    .xs-pb-16X {
        padding-bottom: var(--space16X)
    }

    .xs-pl-16X {
        padding-left: var(--space16X)
    }

    .xs-pr-16X {
        padding-right: var(--space16X)
    }

    .xs-pv-16X {
        padding-top: var(--space16X);
        padding-bottom: var(--space16X)
    }

    .xs-ph-16X {
        padding-left: var(--space16X);
        padding-right: var(--space16X)
    }

    .xs-pa-24X {
        padding: var(--space24X)
    }

    .xs-pt-24X {
        padding-top: var(--space24X)
    }

    .xs-pb-24X {
        padding-bottom: var(--space24X)
    }

    .xs-pl-24X {
        padding-left: var(--space24X)
    }

    .xs-pr-24X {
        padding-right: var(--space24X)
    }

    .xs-pv-24X {
        padding-top: var(--space24X);
        padding-bottom: var(--space24X)
    }

    .xs-ph-24X {
        padding-left: var(--space24X);
        padding-right: var(--space24X)
    }

    .xs-pa-32X {
        padding: var(--space32X)
    }

    .xs-pt-32X {
        padding-top: var(--space32X)
    }

    .xs-pb-32X {
        padding-bottom: var(--space32X)
    }

    .xs-pl-32X {
        padding-left: var(--space32X)
    }

    .xs-pr-32X {
        padding-right: var(--space32X)
    }

    .xs-pv-32X {
        padding-top: var(--space32X);
        padding-bottom: var(--space32X)
    }

    .xs-ph-32X {
        padding-left: var(--space32X);
        padding-right: var(--space32X)
    }

    .xs-lh-1X {
        line-height: var(--space1X)
    }

    .xs-lh-2X {
        line-height: var(--space2X)
    }

    .xs-lh-3X {
        line-height: var(--space3X)
    }

    .xs-lh-4X {
        line-height: var(--space4X)
    }

    .xs-lh-5X {
        line-height: var(--space5X)
    }

    .xs-lh-6X {
        line-height: var(--space6X)
    }

    .xs-lh-8X {
        line-height: var(--space8X)
    }

    .xs-lh-12X {
        line-height: var(--space12X)
    }

    .xs-lh-16X {
        line-height: var(--space16X)
    }

    .xs-lh-24X {
        line-height: var(--space24X)
    }

    .xs-lh-32X {
        line-height: var(--space32X)
    }

    .xs-fs-1X {
        font-size: var(--space1X)
    }

    .xs-fs-2X {
        font-size: var(--space2X)
    }

    .xs-fs-3X {
        font-size: var(--space3X)
    }

    .xs-fs-4X {
        font-size: var(--space4X)
    }

    .xs-fs-5X {
        font-size: var(--space5X)
    }

    .xs-fs-6X {
        font-size: var(--space6X)
    }

    .xs-fs-8X {
        font-size: var(--space8X)
    }

    .xs-fs-12X {
        font-size: var(--space12X)
    }

    .xs-fs-16X {
        font-size: var(--space16X)
    }

    .xs-fs-24X {
        font-size: var(--space24X)
    }

    .xs-fs-32X {
        font-size: var(--space32X)
    }
}