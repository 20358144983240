.resources-standard {
    border-radius: 8px;
    background-color: white;
    padding: 16px;
    display: block !important;
    // width: 100% !important;
    height: auto !important;
  
    .eye-info {
      display: flex;
      align-items: center;
  
    }
  }