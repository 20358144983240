@import './assets/css/theme.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.carousel-container-fix{
  max-width: 1272px;
  margin: 0px auto;
}

.bg-gray {
  background: var(--vds-background-secondary-light, #F6F6F6);
}

.bg-monarch-stone{
  background: var(--Monarch-Stone, #F3EDE0)!important;
}

.bg-white {
  background: #ffffff !important;
}

.LoadingIndicator {
  background-image: url(./assets/images/loader.gif);
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  margin: 100px auto;
}