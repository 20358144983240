.chip-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* .chip-container .chip:first-child{
    margin-right: 8px ;
}

.chip-container .chip:last-child{
    margin-left: 8px ;
}

.chip-container .chip:not(:last-child):not(:first-child)  {
    margin-left: 8px ;
    margin-right: 8px;
} */

.chip-container .chip {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  isolation: isolate;
  border: 1px solid #000000;
  border-radius: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 8px;
}

.chip-container .chip.chip-selected,
.chip.chip-selected p {
  background: #000000;
  color: white;
}

.chip-container .chip.chip-unselected {
  color: black;
  background: #ffffff;
}

.u-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .chip-container {
    justify-content: flex-start;
  }
}
