/** @format */

.card-container {
  width: 100%;
  text-align: left;
}

.card-container .bagde {
  padding: 8px 12px;
  background-color: #000000;
  color: white;
  transform: translate(24px, 54px);
  display: inline-block;
  border-radius: 8px;
}

.course-container .duration {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 90%;
  bottom: 10px;

  .time {
    display: flex;
  }
}



.resource-details {
  display: flex;
  gap: 0 4px;
  margin-bottom: 10px;

  p {
    text-transform: capitalize;
  }
}

.duration-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.time-details {
  display: flex;
  gap: 0 4px;
  margin-top: 10px;
}

.testimoniesCard {
  background-color: white;
  border-radius: 8px;
  width: 100%;
}

.testimoniesCard .heading-Container {
  display: flex;
  align-items: center;
}

.testimoniesCard .heading-Container img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.testimoniesCard .heading-Container .labels {
  text-align: left;
}

@media screen and (max-width: 767px) {
  .testimoniesCard .heading-Container img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
}

.resourceCard .description {
  position: absolute;
  top: 12px;
  width: 100%;
}

.resourceCard .description .label {
  width: 80%;
}

.resourceCard .image {
  position: absolute;
  bottom: 12px;
}

.resourceCard .image img {
  width: 100%;
}

.multi-line-tilelet-title p,
.multi-line-tilelet-title img {
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
  text-transform: capitalize;
}

.bjkVpa {
  padding: 0px !important;
}

.course-carousel div[class^=OuterTileContainer-VDS]{
  padding: 0px !important;
}

.resource-textwrapper{
  position: absolute;
  bottom: 30px;
  @media screen and (max-width: 767px) {
      bottom: 16px;
    }
}