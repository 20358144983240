#closeButton{
    position:absolute;
    top: 12px;
    right: 12px;
    z-index: 50;
    width: 32px;
    height: 32px;
    border:none !important;
    background-color: white;
    border-radius: 50%;
}
#closeButton:focus{
    outline: 1px dashed #000;
    outline-offset: 2px;  
}