
.language-card-container{
  display: flex;
  gap: 16px;
  align-items: flex-start;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}
.language-card{
  position: relative;
  .icon-area{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 8px 16px 16px 16px;
    text-align: center;
  }
  .bottom-desc{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #d8dada;
  }
}