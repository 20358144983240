/**
 * Copyright Beingana Jim Junior, 2021 and all the contributors. License Cn be found in the LICENCE file
 *
 * @format
 */

$theme: #003cff;
$back: #00000075;

.audio-cover {
  display: flex;
  padding: 24px;
  margin-bottom: 10px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  background: var(--vds-elements-text-icons-borders-lowcontrast-ondark, #333);

  .player-icon {
    display: flex;
    width: 80px;
    height: 80px;
    padding: 20px 23px 20px 22px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--vds-background-primary-dark, #000);

    img {
      width: 35px;
      height: 40px;
      flex-shrink: 0;
    }
  }

  .player-info {
    padding-left: 24px;
    .audio-title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      color: var(--vds-elements-text-icons-borders-primary-ondark, #fff);
      font-feature-settings:
        'clig' off,
        'liga' off;
      text-overflow: ellipsis;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    .audio-published-date {
      color: var(
        --vds-elements-text-icons-borders-lowcontrast-onlight,
        #d8dada
      );
      font-feature-settings:
        'clig' off,
        'liga' off;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }
  }
  @media only screen and (max-width: 767px) {
    .audio-title {
      -webkit-line-clamp: 2 !important;
      margin-bottom: 10px;
    }
    }
  @media only screen and (max-width: 767px) {
    .player-icon {
      width: 48px;
      height: 48px;
      padding: 10px;
    }
  }

  .audio-player {
    display: flex;
    justify-content: space-between;
    padding-top: 24px;

    .player-progress {
      width: 100%;

      .MuiSlider-rail,
      .MuiSlider-thumb {
        color: #ffffff;
      }
    }

    .player-timestamps {
      display: flex;
      justify-content: space-between;

      .player-time {
        color: var(--vds-elements-text-icons-borders-primary-ondark, #fff);
        font-feature-settings:
          'clig' off,
          'liga' off;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
      }
    }

    .player-button {
      padding-right: 40px;
      padding-left: 40px;

      button {
        background: transparent;
        border: none;
        cursor: pointer;
      }
    }
  }

  .audio-player-mobile {
    flex-direction: column;
    padding-right: 40px;

    .player-button {
      padding: 24px 0;
    }
  }
}

.react-audio-covering-div {
  border-radius: 8px;
  background: var(--vds-elements-text-icons-borders-lowcontrast-ondark, #333);
  width: 100%;
  padding: 0.2rem 0rem;
  color: $theme;
  position: relative;
}
.slider-color {
  border-radius: 25px;
  color: var(--vds-elements-text-icons-borders-brandhighlight, #e00) !important;
}
.playing-animation {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  .first {
    width: 3px;
    transform: scaleY(0.4);
    height: 20px;
    background-color: #006eff;
    border-radius: 5px;
    margin: 1px;
    animation: playing 1s infinite;
  }
  .middle {
    margin: 1px;
    width: 3px;
    height: 20px;
    background-color: #006eff;
    animation: playing1 1s infinite;
    border-radius: 20px;
  }
}
@keyframes playing {
  0% {
    transform: scaleY(0.1);
  }
  50% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0.1);
  }
}
@keyframes playing1 {
  0% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.1);
  }
  100% {
    transform: scaleY(1);
  }
}
.audio-display-controls {
  display: flex;
  div {
    flex: 1;
    text-align: center;
  }
  align-items: center;
  width: 100%;
}
.hundred {
  width: stretch;
  margin-left: 5px;
  margin-right: 5px;
}
.time-stamps-audio_12242334_A_wjsh {
  width: 100%;
  display: flex;
  font-size: 0.7rem;
  color: $theme;
  .fullstime {
    margin-left: auto;
    padding-right: 5px;
  }
  .current {
    padding-left: 5px;
  }
}
.progress-audio-react_dkijs_23443sxjsjAJajAJ3 {
  display: flex;
  align-items: center;
  height: 2px;
  margin-bottom: 5px;
  cursor: pointer;
  background-color: white;
  transition: all 0.4s;
  .finnished {
    background-color: #006eff;
    height: 100%;
    width: 50%;
  }
  .point {
    width: 0px;
    height: 0px;
    border-radius: 50%;
    background-color: aqua;
    &:hover {
      background-color: #006eff;
    }
  }
  &:focus {
    background-color: yellow;
  }
  &:hover {
    height: 5px;
    .point {
      width: 1px;
      height: 10px;
    }
  }
}

.audio-react-p1 {
  display: flex;
  width: 100%;

  align-items: center;
}

.react-audio-play,
.react-audio-rewind,
.react-audio-foward,
.react-audio-controls,
.react-audio-fixed {
  svg {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 1.3rem;
  }
}
.react-audio-controls {
  position: relative;
  display: flex;
  align-items: center;
  .finnished {
    background-color: $theme;
    height: 100%;
  }
  .volume-add {
    opacity: 0;
    display: flex;
    align-items: center;
    width: fit-content;
    transform: scaleX(0);
    height: fit-content;
    color: white;
    transition: all 0.5s;
  }
  .volume-div {
    width: 0px;
    height: 3px;
    margin: auto;
    background: white;
    transition: all 0.5s;
    border-radius: 5px;
  }
  &:hover {
    .volume-add {
      opacity: 1;
      transform: scaleX(1);
    }
    .volume-div {
      width: 80px;
    }
  }
}
.react-audio-covering-div_EJI {
  position: fixed;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.493) 1px 1px 30px;
  top: 10px;
  right: 10px;
  background-color: #f5f5f5;
  width: fit-content;
  display: flex;
  align-items: center;
  color: #006eff;
  z-index: 1000;
  .hundred {
    display: none;
  }
  .playing-animation {
    flex: 1;
    .first {
      width: 3px;
      transform: scaleY(0.4);
      height: 20px;
      background-color: blue;
      border-radius: 5px;
      margin: 1px;
      animation: playing 1s infinite;
    }
    .middle {
      margin: 1px;
      width: 3px;
      height: 20px;
      background-color: blue;
      animation: playing1 1s infinite;
      border-radius: 20px;
    }
  }
  .react-audio-play,
  .react-audio-fixed {
    margin: 10px;
    flex: 1;
  }
}
.miniplayermode_2ueyhud87928_wuh {
  position: absolute;
  top: 0px;
  left: 0px;
  text-align: center;
  color: aqua;
  font-size: 1.1rem;
  font-weight: bold;
  right: 0px;
  bottom: 0px;
  z-index: 3;
  text-shadow: #c0bfbf 1px 1px 5px;
  background-color: rgba(0, 0, 0, 0.397);
  backdrop-filter: blur(2px);
  padding-top: 15px;
}
